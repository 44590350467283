import { AnyAction, CombinedState, combineReducers, Reducer } from 'redux';
import { History } from 'history';
import { connectRouter, RouterState } from 'connected-react-router';
import { ChatRoomReducer, ChatRoomReducerState } from './modules/chat-room/store/chat-room.reducer';
import { MembersReducer, MembersReducerState } from './modules/members/store/members.reducer';

export const getAppReducers = (history: History): Reducer<CombinedState<AppReducerState>, AnyAction> =>
  combineReducers({
    chatRoom: ChatRoomReducer,
    members: MembersReducer,
    router: connectRouter(history)
  });

export type AppReducerState = {
  chatRoom: ChatRoomReducerState;
  members: MembersReducerState;
  router: RouterState;
};
