import React from 'react';
import styles from './CloseButton.module.scss';

type CloseButtonProps = {
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const CloseButton = ({ disabled = false, onClick }: CloseButtonProps): JSX.Element => {
  return (
    <button type="button" onClick={onClick} className={styles.closeButtonButton} disabled={disabled}>
      <svg
        width="12"
        height="12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={[styles.closeButton, disabled ? styles.closeButton__disabled : ''].join(' ')}
      >
        <path
          d="M1.469 0 .062 1.406l4.281 4.281L0 10.031l1.406 1.406L5.75 7.093l4.312 4.313 1.407-1.407-4.313-4.312 4.25-4.25L10 .031l-4.25 4.25L1.469 0z"
          fill="#C4C4C4"
        />
      </svg>
    </button>
  );
};
