import React from 'react';
import styles from './ContentBox.module.scss';

type HeaderContentProps = {
  children: JSX.Element | Array<JSX.Element>;
  footer?: 'full' | 'simple' | 'none';
  forceNavbarMobile?: boolean;
  className?: string;
};

export const ContentBox = ({
  children,
  className,
  footer = 'full',
  forceNavbarMobile = false
}: HeaderContentProps): JSX.Element => {
  return (
    <div
      className={[
        styles.MainContainer,
        styles['MainContainer__Footer' + footer[0].toUpperCase() + footer.slice(1)],
        forceNavbarMobile ? styles.MainContainer__forceNavbarMobile : '',
        className ?? ''
      ].join(' ')}
    >
      {children}
    </div>
  );
};
