import { applyMiddleware, compose, createStore, Store, Middleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { getAppReducers } from './App.reducers';
import { appMiddlewares } from './App.middlewares';

const { NODE_ENV } = process.env;

export const history = createBrowserHistory();

export const configureStore = (initialState = {}): Store => {
  const middlewares = [thunkMiddleware, routerMiddleware(history), ...appMiddlewares] as Middleware[];

  if (NODE_ENV !== 'production') {
    middlewares.push(logger);
  }

  const enhancers = [applyMiddleware(...middlewares)];

  const reducer = getAppReducers(history);

  const store = createStore(reducer, initialState, compose(...enhancers));

  return store;
};
