import React from 'react';
import logo from '../../../../logo.svg';
import styles from './HeaderLayout.module.scss';
import { LanguageMenu } from '../../../languages/components/LanguageMenu/LanguageMenu.component';

type HeaderLayoutProps = {
  forceNavbarMobile: boolean;
};

export const HeaderLayout = ({ forceNavbarMobile }: HeaderLayoutProps): JSX.Element => {
  return (
    <nav
      className={`bg-gradient-primary ${forceNavbarMobile ? styles.AppNav__forceNavbarMobile : ''} ${styles.AppNav}`}
    >
      <div className="container d-flex justify-content-between">
        <img src={logo} alt="logo" className={`${styles.Logo} img-fluid`} />
        <LanguageMenu />
      </div>
    </nav>
  );
};
