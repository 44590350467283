import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { format as formatDate, formatRelative, formatDistance, isDate } from 'date-fns';
import { enUS, es, zhCN, pt, ru, arSA } from 'date-fns/locale'; // import all locales we need

const locales = {
  en: enUS,
  es,
  ar: arSA,
  zh: zhCN,
  pt,
  ru
} as Record<string, Locale>; // used to look up the required locale

// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationAR from './locales/ar/translation.json';
import translationZH from './locales/zh/translation.json';
import translationPT from './locales/pt/translation.json';
import translationRU from './locales/ru/translation.json';

const resources = {
  en: { translation: translationEN },
  es: { translation: translationES },
  ar: { translation: translationAR },
  zh: { translation: translationZH },
  pt: { translation: translationPT },
  ru: { translation: translationRU }
} as Resource;

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    // fallbackLng: 'en',
    lng: 'en',
    // debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng) => {
        if (isDate(value) && format && lng) {
          const locale = locales[lng];
          if (format === 'short') return formatDate(value, 'P', { locale });
          if (format === 'long') return formatDate(value, 'PPPP', { locale });
          if (format === 'relative') return formatRelative(value, new Date(), { locale });
          if (format === 'ago')
            return formatDistance(value, new Date(), {
              locale,
              addSuffix: true
            });

          return formatDate(value, format, { locale });
        }
        return value;
      }
    }
  });

export default i18n;
