/* eslint react-hooks/exhaustive-deps: off */
import React, { useState, useEffect } from 'react';
import styles from './Checkbox.module.scss';

type CheckboxProps = {
  children: JSX.Element | Array<JSX.Element>;
  inputName: string;
  errorMessage: string;
  onCheckboxClick?: () => void | undefined;
  onCheckboxChange?: () => void | undefined;
  handleError: (origin: string, add?: boolean) => void;
  state: boolean;
};

export const Checkbox = ({
  children,
  inputName,
  errorMessage,
  onCheckboxClick,
  onCheckboxChange,
  handleError,
  state
}: CheckboxProps): JSX.Element => {
  const [error, setError] = useState('');

  useEffect(
    function validateCheckbox() {
      if (!state) {
        setError(errorMessage);
        handleError(inputName);
      } else {
        setError('');
        handleError(inputName, false);
      }
    },
    [state]
  );

  useEffect(function initInputWithErrorStateUntilChangeEvent() {
    setError('');
    handleError(inputName);
  }, []);

  return (
    <label className={styles.checkbox}>
      <span className={styles.checkbox_input}>
        <input type="checkbox" name={inputName} onClick={onCheckboxClick} onChange={onCheckboxChange} checked={state} />
        <span className={styles.checkbox_control}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
            <path fill="none" stroke="currentColor" strokeWidth="1.8" d="M1.73 12.91l6.37 6.37L22.79 4.59" />
          </svg>
        </span>
      </span>
      <span className={styles.radio_label}>{children}</span>
      {error && <span className={styles.checkbox_error}>{error}</span>}
    </label>
  );
};
