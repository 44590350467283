import React from 'react';
import styles from './HeaderContent.module.scss';

type HeaderContentProps = {
  title?: string;
  children?: JSX.Element | Array<JSX.Element> | undefined;
  className?: string;
};

export const HeaderContent = ({ title, children, className }: HeaderContentProps): JSX.Element => {
  return (
    <header className={`${styles.AppHeader} ${className}`}>
      {title && <h1 className={`${styles.Title} txt-title`}>{title}</h1>}
      {children}
    </header>
  );
};
