import React from 'react';

export const RussianFlag = (): JSX.Element => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.3 7.595c1.327-5.36 6.744-8.626 12.105-7.3 5.36 1.327 8.626 6.744 7.3 12.105-1.327 5.356-6.743 8.626-12.105 7.3C2.24 18.373-1.03 12.952.3 7.595z"
        fill="#FC1923"
      />
      <path d="M.584 6.639H19.41A9.977 9.977 0 0012.399.295C7.367-.95 2.285 1.855.585 6.64z" fill="#F2EEE5" />
      <path
        d="M19.78 12.05c.006-.02.01-.04.016-.066.005-.035.015-.075.02-.111a.293.293 0 00.01-.066c.005-.04.015-.076.02-.116.006-.02.006-.04.01-.056.01-.046.016-.096.026-.142 0-.01.005-.02.005-.03.01-.056.015-.116.025-.172 0-.015.005-.03.005-.046l.015-.126c0-.02.006-.04.006-.061a.82.82 0 00.01-.111c0-.02.005-.04.005-.061.005-.04.005-.076.01-.117 0-.02.005-.04.005-.06.005-.046.005-.086.01-.132 0-.015 0-.025.005-.04.005-.056.005-.117.01-.173v-.03c0-.045.005-.096.005-.142v-.055-.117-.06-.117-.056c0-.04 0-.086-.005-.126v-.046a7.941 7.941 0 00-.025-.39c-.005-.04-.005-.08-.01-.126 0-.02-.005-.036-.005-.056-.005-.04-.01-.076-.01-.116 0-.02-.006-.036-.006-.056l-.015-.127c0-.015-.005-.03-.005-.045-.015-.111-.03-.228-.05-.34 0-.01-.005-.02-.005-.03-.01-.045-.016-.09-.026-.136-.005-.016-.005-.03-.01-.046a1.01 1.01 0 00-.025-.121c-.005-.016-.005-.03-.01-.051-.01-.04-.015-.081-.026-.127-.005-.015-.005-.025-.01-.04-.01-.05-.025-.101-.035-.152l-.005-.01c-.015-.05-.026-.106-.04-.157a10.057 10.057 0 00-.264-.866H.584a9.959 9.959 0 00-.46 4.926c0 .005 0 .01.004.015.01.046.015.096.025.142 0 .01.006.02.006.03.01.046.015.086.025.132 0 .015.005.025.01.04.01.041.015.082.025.122.005.015.005.03.01.046.01.04.016.075.026.116l.015.056c.01.035.015.07.025.111.005.025.015.056.02.081l.03.122c.011.04.021.08.036.121l.015.05c.01.041.02.077.036.117l.015.056c.01.035.025.076.036.111l.015.05c.01.036.025.077.035.112l.015.05c.016.041.026.077.04.117.006.01.006.02.011.025.01.026.015.046.025.071h18.752c.01-.025.015-.045.025-.07l.06-.178c0-.005.006-.01.006-.015.025-.076.05-.157.076-.233.005-.01.005-.02.01-.03.02-.071.045-.147.066-.223l.015-.045c.025-.086.045-.178.07-.264.016-.055.031-.116.041-.172.005-.02.01-.035.01-.056.01-.025.015-.065.026-.106z"
        fill="#0B327C"
      />
    </svg>
  );
};
