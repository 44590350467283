import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';

import { authService } from '../shared/services/authService';

import { setPatientCode } from '../chat-room/store/chat-room.actions';

import { LANGUAGE_KEY_STORE } from '../languages/constants/languages.constants';
import { appRoutes } from '../core/constants/routes.constants';

import { InterfaceWrapper } from '../shared/components/InterfaceWrapper/InterfaceWrapper.component';
import { SignUpForm } from './components/SignUpForm/SignUpForm.component';
import { SelectLanguage } from '../languages/SelectLanguage.component';
import { tokenService } from '../core/services/token.service';

export const SettingsFlow = (): JSX.Element => {
  const { t } = useTranslation();
  const { patientCode } = useParams<{ patientCode: string }>();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { CHAT_ROOM } = appRoutes;
  const [languageSettled, setLanguageSettled] = useState(localStorage.getItem(LANGUAGE_KEY_STORE));

  const goChatRoom = useCallback(() => {
    localStorage.patientCode = patientCode;
    dispatch(setPatientCode(patientCode));
    history.push(CHAT_ROOM.path);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }, [CHAT_ROOM.path, dispatch, history, patientCode]);

  const isValidPatientCode = useCallback(() => {
    if (localStorage.patientCode !== patientCode) {
      tokenService.clear();
    }
    return patientCode.length === 6 && !isNaN(Number(patientCode));
  }, [patientCode]);

  const signUpFormSuccess = (formData: { firstName: string; lastname: string }) => {
    const query = new URLSearchParams(location.search);
    // get Token URL for surrogate FLOW
    const tokenQuery = query.get('token') || undefined;
    authService
      .logIn({
        token: tokenQuery,
        lastName: formData.lastname,
        firstName: formData.firstName
      })
      .then(() => goChatRoom());
  };

  useEffect(
    function validPatientCode() {
      if (!isValidPatientCode()) {
        // patientCode without 6 chars
        history.push('/');
      }
    },
    [history, isValidPatientCode]
  );

  useEffect(
    function isUserLoggedIn() {
      if (isValidPatientCode() && authService.isUserLoggedIn()) {
        // user is auth, move to chat-room
        goChatRoom();
      }
    },
    [goChatRoom, isValidPatientCode]
  );

  return (
    <>
      {!languageSettled && (
        <SelectLanguage
          languageSet={(language) => {
            setLanguageSettled(language);
          }}
        />
      )}
      {languageSettled && (
        <InterfaceWrapper title={t('members.section.sign-up.title', 'You’ve been invited to the Secure Chat Session')}>
          <SignUpForm success={signUpFormSuccess} />
        </InterfaceWrapper>
      )}
    </>
  );
};
