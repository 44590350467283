import { Home } from '../../home/Home.component';
import { lazy } from 'react';
import { SettingsFlow } from '../../settings-flow/SettingsFlow.component';
import { RouteProps } from 'react-router-dom';
import { History } from 'history';

export const Routes: RouteConfig[] = [
  {
    key: 'CHAT_ROOM',
    path: '/chat-room',
    exact: true,
    component: lazy(() => import('../../../modules/chat-room/ChatRoom.container'))
  },
  {
    key: 'MEMBERS_MANAGER',
    path: '/chat-room/members',
    exact: true,
    component: lazy(() => import('../../../modules/members/Members.container'))
  },
  {
    key: 'SETTINGS_FLOW',
    path: '/:patientCode',
    exact: true,
    component: SettingsFlow
  },
  {
    key: 'DEFAULT',
    path: '**',
    component: Home
  }
];

export interface RouteConfig extends RouteProps {
  key: string; // for identify route
  routes?: RouteConfig[]; // for nested routes
  path: History.Path;
}

export const appRoutes: { [key: string]: RouteConfig } = Routes.reduce((obj, item) => {
  return {
    ...obj,
    [item['key']]: item
  };
}, {});
