import React from 'react';
import styles from './TermsAndConditionsCheckbox.module.scss';

import { Checkbox } from '../../../shared/components/Checkbox/Checkbox.component';
import { Trans, useTranslation } from 'react-i18next';

type TermsAndConditionsCheckboxProps = {
  onCheckboxClick?: () => void | undefined;
  onTermsAndConditionsClick: React.MouseEventHandler<HTMLButtonElement>;
  handleError: (origin: string, add?: boolean) => void;
  setAcceptedState: (newState: boolean) => void;
  acceptedState: boolean;
};

export const TermsAndConditionsCheckbox = ({
  onCheckboxClick,
  onTermsAndConditionsClick,
  handleError,
  setAcceptedState,
  acceptedState
}: TermsAndConditionsCheckboxProps): JSX.Element => {
  const { t } = useTranslation();
  const ifCheckboxIsEnabled = () => {
    const newCheckedState = !acceptedState;
    if (newCheckedState && onCheckboxClick) {
      onCheckboxClick();
    }
  };

  const maybeUntickCheckbox = () => {
    const newCheckedState = !acceptedState;
    if (!newCheckedState) {
      setAcceptedState(false);
    }
  };

  return (
    <Checkbox
      onCheckboxClick={ifCheckboxIsEnabled}
      onCheckboxChange={maybeUntickCheckbox}
      handleError={handleError}
      state={acceptedState}
      errorMessage={t('terms-and-conditions.checkbox-error', 'Terms and Conditions must be accepted')}
      inputName="terms-and-conditions"
    >
      <Trans i18nKey="terms-and-conditions.checkbox">
        I accept the{' '}
        <button className={styles.textOnlyButton} onClick={onTermsAndConditionsClick}>
          Terms and Conditions
        </button>
      </Trans>
    </Checkbox>
  );
};
