import { ArabicFlag } from '../components/svg/ArabicFlag.component';
import { RussianFlag } from '../components/svg/RussianFlag.component';
import { SpanishFlag } from '../components/svg/SpanishFlag.component';
import { EnglishFlag } from '../components/svg/EnglishFlag.component';
import { ChineseFlag } from '../components/svg/ChineseFlag.component';
import { PortugueseFlag } from '../components/svg/PortuguseFlag.component';
import { Language } from '../types/Language.types';

export const LANGUAGES: Language[] = [
  { id: 'en', text: 'English', img: EnglishFlag() },
  { id: 'es', text: 'Español', img: SpanishFlag() },
  { id: 'ar', text: 'Arabic', img: ArabicFlag() }, // todo: translate
  { id: 'zh', text: 'Chinese', img: ChineseFlag() }, // todo: translate
  { id: 'pt', text: 'Portugues', img: PortugueseFlag() }, // todo: verify translate
  { id: 'ru', text: 'Russian', img: RussianFlag() } // todo: translate
];

export const LANGUAGE_KEY_STORE = 'lang_key';

export const SHOW_LANGUAGE_ROUTES = ['/chat-room', '/chat-room/members'];

export const LANGUAGE_DEFAULT = 'en';
