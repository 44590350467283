import axios from 'axios';
import { docclokerAppConfig } from '../../config/config';
import { tokenService } from './services/token.service';
import { authService } from '../shared/services/authService';

export const googleApiAxiosInstance = axios.create({
  baseURL: docclokerAppConfig.GOOGLE_TRANSLATE_API_URL,
  headers: {
    accept: 'application/json',
    'content-type': 'application/json'
  }
});

export const docclokerApiAxiosInstance = axios.create({
  baseURL: docclokerAppConfig.API_URL,
  headers: {
    accept: 'application/json',
    'content-type': 'application/json'
  }
});

docclokerApiAxiosInstance.interceptors.request.use((value) => {
  const token = tokenService.token;
  if (token) {
    value.headers.Authorization = `${token.token_type} ${token.access_token}`;
  }
  return value;
});

docclokerApiAxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.request.status === 401 && !originalRequest._retry && authService.dataStorage) {
      originalRequest._retry = true;
      await authService.logIn(authService.dataStorage);
      return docclokerApiAxiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);
