import React, { useEffect, useState } from 'react';

import { TermsAndConditionsModal } from './components/TermsAndConditionsModal/TermsAndConditionsModal.component';
import { TermsAndConditionsCheckbox } from './components/TermsAndConditionsCheckbox/TermsAndConditionsCheckbox.component';
import { Trans, useTranslation } from 'react-i18next';
import styles from './TermsAndConditions.module.scss';

type TermsAndConditionsProps = {
  handleError: (origin: string, add?: boolean) => void;
  setAcceptedState: (newState: boolean) => void;
  acceptedState: boolean;
};

export const TermsAndConditions = ({
  handleError,
  setAcceptedState,
  acceptedState
}: TermsAndConditionsProps): JSX.Element => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const onTermsAndConditionsClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setModalOpen(!modalOpen);
  };

  const acceptTermsAndConditions = () => {
    setModalOpen(false);
    setAcceptedState(true);
  };

  useEffect(
    function hideBodyScrollWhenModalOpen() {
      if (modalOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    },
    [modalOpen]
  );

  return (
    <>
      <TermsAndConditionsCheckbox
        acceptedState={acceptedState}
        setAcceptedState={setAcceptedState}
        handleError={handleError}
        onCheckboxClick={() => setModalOpen(!modalOpen)}
        onTermsAndConditionsClick={onTermsAndConditionsClick}
      />
      <TermsAndConditionsModal
        title={t('terms-and-conditions.modal-title', 'Terms of Use for Doc-OR®')}
        modalIsOpen={modalOpen}
        onCloseClick={() => setModalOpen(false)}
        onControlClick={acceptTermsAndConditions}
      >
        <div className={`${styles.modalContent} font-modal-content`}>
          <Trans i18nKey={'terms-and-conditions.member-full-terms-and-conditions'}>
            <p>
              Thank you for using Doc-OR, and for taking some time to review these Terms of Use. We hope that you find
              our app and our services to be beneficial. These Terms of Use (“Terms”) govern your use of: (a) the
              Doc-OR® mobile web application (the “App”) and online service, (b) the web site at www.doc-or.com and all
              related portals and web sites (the “Sites”), and (c) all related technologies, software, content, and
              services. The foregoing items are collectively referred to in these Terms as the “Services.” These Terms
              constitute a binding, legal agreement between Fast Pathway, Inc. (“Fast Pathway” or “we”, the producer of
              the Services) and you. Please read these Terms carefully. By clicking or checking “I Accept” or similar
              language, or otherwise by using the Services, you agree to these Terms, including without limitation the
              Privacy Policy incorporated herein. If you do not agree to be bound by these Terms, then we do not grant
              you any right to install or use the Services.
            </p>
            <p>
              THE SERVICES DO NOT CONSTITUTE MEDICAL TREATMENT OR MEDICAL OR HEALTHCARE ADVICE, AND FAST PATHWAY IS NOT
              A HEALTHCARE PROVIDER. THE APP AND THE SERVICES ARE NOT INTENDED TO BE USED FOR TREATING OR DIAGNOSING ANY
              MEDICAL CONDITION. IF YOU ARE SEEKING MEDICAL TREATMENT OR EMERGENCY CARE, HAVE AN ILLNESS OR INJURY, OR
              HAVE WORSENING OF ANY SYMPTOMS, CONTACT YOUR HEALTHCARE PROVIDER, OR IN AN EMERGENCY IN THE UNITED STATES
              DIAL 911, RIGHT AWAY.
            </p>
            <p>
              We reserve the right to change these Terms at any time by posting the changes on our Sites and/or within
              the App. Your continued use of Services following such modifications will be conclusively deemed
              acceptance of any changes to these Terms.
            </p>
            <p>Additional Notices and Disclaimers</p>
            <p>
              THE SERVICES AND ALL RELATED CONTENT ARE PROVIDED AS-IS, AND YOUR USE OF THE SERVICES IS AT YOUR OWN RISK.
              FAST PATHWAY AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES AND AGENTS MAKE NO REPRESENTATION OR
              WARRANTY RELATING TO THE SERVICES, INCLUDING ANY INFORMATION AND CONTENT ACCESSIBLE DIRECTLY OR INDIRECTLY
              FROM THE SERVICES. FAST PATHWAY DISCLAIMS TO THE MAXIMUM EXTENT PERMITTED BY LAW ALL REPRESENTATIONS AND
              WARRANTIES. WITHOUT LIMITING THE FOREGOING, FAST PATHWAY DISCLAIMS ALL WARRANTIES: (A) OF MERCHANTABILITY
              AND FITNESS FOR A PARTICULAR PURPOSE, (B) AGAINST INFRINGEMENT OF ANY THIRD PARTY INTELLECTUAL RIGHTS, (C)
              RELATED TO THE TRANSMISSION OR DELIVERY OF THE SERVICES OR ITS CONTENT, (D) RELATING TO THE ACCURACY,
              RELIABILITY, TIMELINESS, CORRECTNESS, OR COMPLETENESS OF THE SERVICES (INCLUDING WITHOUT LIMITATION
              REPORTING OF PROGRESS UPDATES), AND (E) OTHERWISE RELATING TO THE PERFORMANCE OF THE SERVICES.
            </p>
            <p>
              The content and features associated with the Services are for general informational, progress update
              communication, and/or other communication purposes and are not intended to provide a diagnosis or take the
              place of consultation or advice from a health care professional. Although physicians and other healthcare
              professionals may use the Services to communicate with you, use of the Services does not form any medical
              professional/patient relationship. Persons under the age of 18 are not permitted to use the Services and
              are advised to talk with their parents or guardians about seeing a physician or other qualified healthcare
              provider. Fast Pathway does not warrant that the functioning of the Services will be uninterrupted or that
              the Services and any associated information will meet your requirements. No oral or written information or
              advice given by Fast Pathway or any of its employees, representatives, agents or distributors will create
              a warranty.
            </p>
            <p>Healthcare Providers Listed Through the Services</p>
            <p>
              As part of the Services, we may include information about healthcare providers, including progress updates
              from healthcare providers. At all times, you are solely responsible for choosing your healthcare
              providers. Fast Pathway does not provide a referral service and does not recommend or endorse any specific
              physicians or other healthcare providers, or the use of any procedures, tests, pharmaceuticals, or medical
              devices. Information regarding healthcare providers, including their progress updates, and other
              information and content made available through the Services, may be incomplete or out of date, or
              otherwise inaccurate. Fast Pathway is not responsible for verifying any such information or for the
              quality of any medical or healthcare services you may receive. Certain healthcare providers may acquire
              sponsorships on our Services, allowing them a priority placement or other enhancements in their listings.
              This does not constitute an endorsement by us. We may, in our discretion, remove listings of a healthcare
              provider if we are aware that the provider does not have active licenses, has engaged in sanctionable
              conduct, or is otherwise incorrect or inappropriate. However, we provide no warranty or guarantee that we
              will undertake any reviews of these matters. You are solely responsible for paying your healthcare-related
              expenses and for any disputes of any kind with your healthcare provider.
            </p>
            <p>Registration</p>
            <p>
              To use certain parts of the Services, you may need to register and provide basic information to us, such
              as your name, phone number, email address, zip code, and your choice for password. You represent that the
              information that you provide about yourself in the account registration process is complete and accurate.
              You agree that we may use your email address and phone number to send you notifications and other
              account-related communications which may include surveys, feature updates, url links, Services issues,
              special announcements and/or marketing promotions. You are responsible for keeping your password
              confidential, keeping your medical and other personal information private, and for promptly notifying us
              if your password has been hacked or stolen. You may not register for an account on behalf of an individual
              other than yourself, and you may not register on behalf of any entity.
            </p>
            <p>Intellectual Property</p>
            <p>
              You acknowledge that Fast Pathway and its licensors solely own all rights in and to the Services, and the
              associated content, software, scripts, graphics, photos, video, sound, source code, algorithms, data
              structures, methods, screen formats, and concepts, including all related patent, copyright, trade secret,
              trademark, and other intellectual property rights. You will not remove any copyright or other proprietary
              notices from the Services or any copies thereof. Fast Pathway reserves all rights not expressly granted
              hereunder. The license granted herein does not constitute a sale of any intellectual property associated
              with the Services. You agree not to challenge Fast Pathway’s ownership or rights in and to the Services
              and related materials. You acknowledge and agree that any violation of the terms of this Section would
              irreparably harm Fast Pathway and that Fast Pathway may enforce the terms of this Section through
              injunctive relief, without limitation to any other rights and remedies available to it. These Terms covers
              any future releases and updates of the Services that you may use, unless any such update is subject to a
              separate agreement. Your rights to the previous versions of the App terminate once an update has been
              installed. You agree to access the Services and associated data only through the interface that is
              provided by us for accessing the Services. You agree not to use any automated means, including but not
              limited to agents, robots, scripts, spiders, offline readers, and screen scraping tools, to access,
              monitor, download or copy any part of the Services, including without limitation any content associated
              with the Services, unless we have provided prior written consent. All rights in trademarks, trade names,
              logos, service marks, and trade dress (“Trademarks”), displayed in connection with the Services belong
              exclusively to Fast Pathway or its owners. You are prohibited from imitation, dilution, or confusing or
              misleading uses. Nothing gives you any license or right under the Trademarks or any other intellectual
              property of Fast Pathway or any third party unless expressly stated in these Terms.
            </p>
            <p>Downloadable Materials</p>
            <p>
              Permission is granted to display, copy, and download the materials on the Sites for personal,
              noncommercial use only; provided you do not distribute or modify the materials and that you retain all
              copyright and other proprietary notices contained in the materials. You may not, however, distribute,
              copy, reproduce, display, republish, download, or transmit any material on the Sites for commercial use
              without prior written approval of Fast Pathway. Use of images of people and places displayed on the Sites
              for commercial or non-commercial purposes is strictly prohibited.
            </p>
            <p>Your Right to Use the Services</p>
            <p>
              Fast Pathway grants you a non-exclusive, non-transferable, limited license to use the App in executable
              form for your personal, non-commercial use only on any mobile device or tablet that you own or control
              (and, if you own or control an Apple iPhone, iPad or iPod, as permitted by the Usage Rules of the App
              Store Terms and Conditions (the “Usage Rules”)). This license does not allow you to use the App on any
              device that you do not own or control, and you may not distribute or make the App available over a network
              where it could be used by multiple devices at the same time. You may use the App only for your personal,
              non-commercial benefit, and not to assist or aid any other person. Fast Pathway grants you a
              non-exclusive, non-transferable, limited license to access and display the Sites solely on your computer
              or other personal device for your personal, non-commercial use of the Sites. You may print a copy of
              online materials and contents for your own personal, non- commercial use. Except for such permitted
              access, display and use, you may not copy, reproduce, republish, transmit, distribute, use or create
              derivative works of the Sites without Fast Pathway’ prior written consent. You represent and warrant that
              you have the right and authority to agree to these Terms and are not prohibited from using the Services.
              You may not rent, lease, lend, sell, redistribute or sublicense the Services. You may not copy (except as
              expressly permitted by this license and if applicable the Usage Rules), decompile, reverse engineer,
              disassemble, attempt to derive the source code of, modify, or create derivative works of the Services, any
              updates, or any part thereof (except as and only to the extent any foregoing restriction is prohibited by
              applicable law). Any attempt to do so is a violation of our rights and the rights of our licensors. If you
              breach this restriction, you may be subject to prosecution and damages. You agree to use the Services in
              compliance with all applicable laws, rules, regulations and standards, including but not limited to laws,
              rules, regulations and standards relating to the privacy of individually identifiable information.
              Software, functionality and other materials that are made available for downloading, access, or use on or
              from the Services may, in addition, have their own license terms, conditions, and notices, and you agree
              to be governed by such terms, conditions, and notices. Your use of the Services is conditioned upon your
              acceptance and compliance with these Terms and any other applicable terms, conditions, and notices.
            </p>
            <p>Privacy</p>
            <p>
              Your use of this App is governed by our Privacy Policy included on the Site (“Privacy Policy”). We are
              committed to safeguarding and protecting your personal information, including health information. You
              agree to the use and disclosure of your personal information as set forth in the Privacy Policy. In
              particular, we may transmit your personal information to healthcare providers when you are setting an
              appointment or are communicating with your healthcare provider through our Services. Prohibited Activities
              Generally. You agree not to, in connection with your use of the Services: • violate any local, state,
              national or foreign laws and regulations; • delete or revise any material or other information included by
              us on the Services, except where expressly allowed by the Services; • access any information about other
              users, including e-mail addresses; • take any action that unreasonably or disproportionately utilizes the
              Services’ infrastructure or interferes with the proper working of the Services; • solicit other users to
              join or support other services or organizations; • engage in any other conduct that restricts or inhibits
              any other person from using or enjoying the Services; • violate or attempt to violate the security of the
              Services; • knowingly include or use any false or inaccurate information in any profile; • upload, post,
              email or otherwise transmit any advertising, promotional materials, junk mail, spam, chain letters,
              “pyramid schemes” or any other form of solicitation, as well as viruses or other computer code that may
              interrupt, destroy, or limit the functionality of the Services; • upload, post, email or otherwise
              transmit any content to which you do not have the lawful right to copy, transmit and display (including
              any content that would violate any confidentiality or fiduciary obligations that you might have with
              respect to the content); • upload, post, email or otherwise transmit any content that infringes the
              intellectual property rights or violates the privacy rights of any third party (including without
              limitation copyright, trademark, patent, trade secret, or other intellectual property right, or moral
              right or right of publicity); or • encourage or instruct any other person or entity to do any of the
              foregoing.
            </p>
            <p>User Contributions and Reviews</p>
            <p>
              The Services may include the ability to post information and content, including wait times, provider
              reviews and commentary, or send communications to providers (each, a “User Contribution”). Depending on
              the feature of the Services you are using, your User Contribution can be publicly available, or
              alternatively designated for limited recipients only. You agree to post and send User Contributions that
              are proper and comply with these Terms. You agree not to: • provide any User Contribution that is
              untruthful, illegal, misleading, defamatory, indecent, obscene, inappropriate, threatening, harassing,
              bigoted, abusive, degrading, infringing of any third-party proprietary rights, invasive of personal
              privacy, or otherwise offensive or objectionable; • supply any User Contribution that includes any virus,
              worm or other harmful code or software; • create a false identity, impersonate any person, or misrepresent
              your affiliation with any other person or entity including Fast Pathway. You will not forge headers or
              otherwise manipulate identifiers (including URLs) in order to disguise the origin of any User
              Contribution; or • provide any User Contribution that is otherwise in violation of these Terms. Fast
              Pathway has the right, but not any obligation, to monitor and/or review all User Contributions. Fast
              Pathway reserves the right at all times to disclose any User Contribution to a third party as Fast Pathway
              deems necessary to satisfy any applicable law, regulation, legal process or governmental request or refuse
              to post or to remove any User Contribution for any reason in Fast Pathways’ sole discretion. You agree to
              be careful about the personal information that you disclose about yourself and others, and in particular,
              you should not disclose sensitive, proprietary or confidential information about others in any public User
              Contribution. In making User Contributions, you agree to respect other persons’ privacy rights. Any
              information disclosed by You as part of a publicly available User Contribution is not private and is not
              subject to any privacy protections.
            </p>
            <p>
              The Services may enable you to evaluate and rate healthcare provider, and may include the assignment of a
              numeric rating (such as 1-5) as a performance indication and ability to make comments. All evaluations
              must be made in good faith and be fair and objective. You agree not to review a practice with which you or
              your family member or friend is affiliated, or that competes with you or a family member or friend. If you
              post a comment regarding a healthcare provider, you are solely responsible for ensuring that the comment
              complies with all use and content restrictions in these Terms.
            </p>
            <p>Rights to User Contributions</p>
            <p>
              You retain ownership of all of your copyright interests in your User Contributions. By submitting or
              sending a User Contribution, you: (i) represent and warrant that the User Contribution is original to you
              and that no other party has any rights thereto, and (ii) grant us (subject to applicable provisions of our
              Privacy Policy) a royalty-free, unrestricted, worldwide, perpetual, irrevocable, non-exclusive and fully
              transferable, assignable and sublicensable right and license to use, copy, reproduce, modify, edit, adapt,
              publish, translate, create derivative works from, distribute, transfer, perform and display such material
              (in whole or part) and to incorporate User Contributions in other works in any form, media, or technology
              now known or later developed. You also agree that Fast Pathway is free to use any ideas, concepts,
              know-how, or techniques that you provide to us for any purpose. We are not responsible for maintaining any
              User Contributions that you provide to us, and we may delete or destroy any such User Contributions at any
              time. User Contributions that are for public disclosure will be deemed not to be confidential.
            </p>
            <p>Fees</p>
            <p>
              The App and certain Services may be available for a fee, which are described on the App and/or the Sites
              and may be changed from time to time. All fees paid are nonrefundable except as allowed by third-party App
              stores. If there are recurring fees associated with your account, your account and fees will automatically
              renew monthly (or other time period as agreed) and continue until your account is terminated, suspended or
              deactivated. You consent to and accept responsibility for all recurring charges, without further
              authorization from you and without further notice except as required by law. You acknowledge that the
              amount of the recurring fee may change upon prior notice to you. Unless otherwise stated, all fees are in
              U.S. dollars. You are responsible for paying all taxes associated with the Services and any transactions
              entered in connection with the Services. If your payment method fails or your account is past due, we may
              terminate or suspend your use of the Services.
            </p>
            <p>Emails and Texts</p>
            <p>
              Please use caution when e-mailing and texting us or using other electronic communication tools to
              communicate with us. You should consider that all such standard communications tools are not fully secure
              means of communication. Do not send us emails, texts, or other electronic communications with personally
              identifiable health information (“PHI”). There is some risk that any PHI contained in email, texts and
              other electronic communications may be disclosed to, or intercepted, printed, or stored by, unauthorized
              third parties. Fast Pathway cannot ensure the security or confidentiality of messages sent by email,
              texts, and other electronic communications.
            </p>
            <p>Limitation of Liability</p>
            <p>
              Fast Pathway’ liability for breach of these Terms or any other cause of action arising under or relating
              to these Terms is limited to your right to terminate use of the Services. In no event will Fast Pathway,
              its affiliates, officers, employees, or any party involved in creating or delivering the Services be
              liable for any damages of any kind, including without limitation any direct, incidental, consequential,
              indirect, special, or punitive damages, for any personal injury or death, any lost profits or loss of
              revenue, or damages resulting from lost data arising out of or relating to your use of the Services,
              including your reliance on any information provided through the Services or your use of any healthcare
              providers that you found through the Services. Without limiting the foregoing, you agree that in no event
              shall Fast Pathway be liable to you in an amount exceeding $75. If you have a dispute with another user of
              the Services, you release Fast Pathway from any claims, demands, and damages of any kind arising from such
              dispute. We do not guarantee or warrant that access to the Services will not result in, or that any
              content or software made available through the Services will be free of, infection from viruses, worms,
              Trojan horses or other destructive code. These limitations apply whether the alleged liability is based on
              contract, tort, negligence, strict liability, or any other basis, even if Fast Pathway has been advised of
              the possibility of such damage. Because some jurisdictions do not allow the exclusion or limitation of
              incidental or consequential damages, Fast Pathway’s liability in such jurisdictions shall be limited to
              the extent permitted by law.
            </p>
            <p>
              FAST PATHWAY ASSUMES NO LIABILITY AS TO THE RELIABILITY, ACCURACY, VALIDITY, TIMELINESS, USEFULNESS OR
              COMPLETENESS OF ANY INFORMATION CONTAINED IN THE SERVICES.
            </p>
            <p>Indemnification</p>
            <p>
              You agree to defend, indemnify, and hold harmless Fast Pathway, its officers, directors, employees,
              affiliates, agents and its partners and suppliers (including, without limitation third parties who
              contribute to the Services) from any loss, damage, expense, or cost (including, without limitation,
              reasonable attorney’s fees) resulting from or relating to: (a) your access to or use of the Services; (b)
              your violation of any of the provisions of these Terms; (c) any activity related to your account by you or
              any other person accessing the Services through your account, including, without limitation, negligent or
              wrongful conduct; or (d) your violation of any third-party right, including without limitation any
              intellectual property right, publicity, confidentiality, property or privacy right. Fast Pathway reserves
              the right to assume the exclusive defense and control of any matter otherwise subject to indemnification
              by you, in which event you will cooperate with us in asserting any available defenses.
            </p>
            <p>Choice of Law and Jurisdiction</p>
            <p>
              We operate and control the Services from our location in the State of Florida, USA. We make no
              representation that any part of our Services is appropriate for use outside of the United States. If you
              choose to access our Services from locations other than the United States, you do so on your own
              initiative and are responsible for compliance with applicable laws and regulations. These Terms and the
              relationship between you and us are governed by and construed in accordance with the laws of the State of
              Florida, without regard to its principles of conflict of laws. The United Nations Convention on Contracts
              for the International Sale of Goods and the Uniform Computer Information Transactions Act (USA) do not
              apply to these Terms. You and Fast Pathway each irrevocably agrees that the exclusive venue for any action
              or proceeding arising out of or relating to these Terms or our relationship with you, regardless of
              theory, shall be the state and federal courts located in Hillsborough County, Florida. You and Fast
              Pathway each irrevocably consent to the personal jurisdiction of these courts and waive any and all
              objections to the exercise of jurisdiction by these courts and to this venue. Notwithstanding the
              foregoing, however, you agree that Fast Pathway may commence and maintain an action or proceeding seeking
              injunctive or other equitable relief in any court of competent jurisdiction.
            </p>
            <p>Children</p>
            <p>
              The Services are not intended or licensed for use by children under age 18. We will not knowingly collect
              personally identifiable information in connection with the Services from children under 18. If you are
              under 18 years old, please do not use the Services.
            </p>
            <p>Changes</p>
            <p>
              We may modify, delete and replaces features in, and enhance the Services from time to time without notice
              to you.
            </p>
            <p>Termination</p>
            <p>
              We may discontinue offering the Services and we may suspend or terminate your right to use the Services at
              any time, for any or no reason. We may also suspend or terminate your right to use the Services in the
              event we determine in our sole discretion that you have breached these Terms. After such termination, we
              will have no further obligation to provide the Services to you. Your violation of these Terms or
              unauthorized use or access of our intellectual property and/or systems may also result in legal liability
              (including, without limitation, monetary damages) to you. Any suspension, termination, or cancellation
              will not affect provisions in these Terms which by their nature are intended to survive such suspension,
              termination, or cancellation, including without limitation the Sections entitled “Intellectual Property”,
              “Additional Notices and Disclaimers”, “Limitation of Liability”, “Indemnification”, “Choice of Law and
              Jurisdiction”, and “Additional Terms”.
            </p>
            <p>Apple iOS Terms</p>
            <p>
              The terms in this Section apply only to the extent you are using the App on an Apple iOS device (iPhone,
              iPod, or iPad). These Terms are between you and Fast Pathway only, and not with Apple. In the event that
              these Terms provide usage rules that are less restrictive than the Usage Rules set forth for Apps in, or
              otherwise are in conflict with, the Apple App Store Terms and Conditions (which you acknowledge you have
              had an opportunity to review), then the App Store Terms and Conditions shall take precedence. In the event
              of any failure of the App to conform to any applicable warranty, you may notify Apple, and Apple will
              refund the purchase price for the App to you, if applicable. To the maximum extent permitted by applicable
              law, Apple will have no other warranty obligation whatsoever with respect to the App, and any other
              claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any
              warranty will be Fast Pathway’s sole responsibility. You and Fast Pathway acknowledge that: (a) Fast
              Pathway, not Apple, is responsible for addressing any claims by you or any third party relating to the App
              or your possession and/or use of the App, including, but not limited to: (i) product liability claims;
              (ii) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (iii)
              claims arising under consumer protection or similar legislation, and (b) in the event of any third party
              claim that the App or your possession and use of the App infringes that third party’s intellectual
              property rights, Fast Pathway, not Apple, will be solely responsible for the investigation, defense,
              settlement and discharge of any such intellectual property infringement claim. The parties acknowledge and
              agree that Apple, and Apple’s subsidiaries, are third party beneficiaries of these Terms, and that, upon
              acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to
              enforce these Terms against you as a third party beneficiary thereof.
            </p>
            <p>Additional Terms</p>
            <p>
              These Terms, together with our Privacy Policy and any other legal terms and notices published by us on the
              Services, constitute the entire agreement between you and Fast Pathway concerning the Services, and
              supersede all previous written and oral understandings and agreements. If any provision of these Terms is
              deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the
              validity of the remaining provisions of these Terms, which shall remain in full force and effect. No
              waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other
              term, and a party’s failure to assert any right or provision under these Terms shall not constitute a
              waiver of such right or provision. You may not assign or transfer these Terms, by operation of law or
              otherwise, without Fast Pathway’s prior written consent. Any attempt by you to assign or transfer these
              Terms, without such consent, will be null and of no effect. Fast Pathway may assign or transfer these
              Terms, at its sole discretion, without restriction. Subject to the foregoing, these Terms will bind and
              inure to the benefit of the parties, their successors and permitted assigns. You acknowledge and agree
              that any questions, comments, suggestions, ideas, feedback or other information submitted to Fast Pathway
              relating to modifying or improving the Services (“Feedback”), whether solicited or unsolicited, are
              non-confidential and shall become our sole property. Fast Pathway shall own exclusive rights, including
              all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of
              Feedback for any purpose, commercial or otherwise, without acknowledgment or compensation to you. In the
              event your jurisdiction does not allow intellectual property rights to be transferred as described above,
              you hereby grant to Fast Pathway a worldwide, non-exclusive, royalty-free, perpetual license for the use
              of Feedback. You may not use or otherwise export or re-export the App or any software associated with the
              Services except as authorized by United States law and the laws of the jurisdiction in which the App or
              such software was obtained. In particular, but without limitation, the App and such software may not be
              exported or re-exported (a) into any U.S. embargoed countries or (b) to anyone on the U.S. Treasury
              Department&apos;s list of Specially Designated Nationals or the U.S. Department of Commerce Denied
              Person’s List or Entity List. You represent and warrant that (i) you are not located in a country that is
              subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist
              supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted
              parties. The parties are and shall remain independent contractors. Nothing in these Terms shall be
              construed to constitute the parties as partners, joint venturers, agents or anything other than
              independent contractors.
            </p>
            <p>Copyright Policy</p>
            <p>
              Fast Pathway respects the intellectual property rights of others and expects its users to do the same. In
              accordance with the Digital Millennium Copyright Act of 1998, Fast Pathway will respond expeditiously to
              claims of copyright infringement committed using the App that are reported to our Designated Copyright
              Agent identified below. If you are a copyright owner or an agent thereof and believe that your copyright
              has been infringed, you may submit a DMCA notification by filing a notice with our Designated Agent. To
              file a copyright infringement notification with us, please send a written communication that includes the
              following: • Identification of the copyrighted work that you believe has been infringed. • Identification
              of the material that you claim is infringing or which you claim is the subject of infringing activity. •
              Sufficient information to permit us to locate the material. • Information reasonably sufficient to permit
              us to contact you, such as name, address, telephone number and email address. • The following statement:
              &quot;I have a good faith belief that use of the copyrighted materials in the manner complained of is not
              authorized by the copyright owner, its agent, or the law.&quot; • The following statement: &quot;I swear,
              under penalty of perjury, that the information in the notification is accurate and that I am the copyright
              owner or am authorized to act on behalf of the owner of an exclusive right that is allegedly
              infringed.&quot; • Your physical or electronic signature.
            </p>
            <p>
              Such written notice should be sent to us by email: admin@fastpathway.com or letter to the following
              designated copyright agent: Fast Pathway, Inc Attn: Copyright Agent 2202 N Westshore Blvd., Suite 200
              Tampa, Florida 33607 Contact us
            </p>
            <p>
              Please contact us at admin@fastpathway.com if you have any questions concerning our Terms. Last Revised:
              May 2021
            </p>
          </Trans>
        </div>
      </TermsAndConditionsModal>
    </>
  );
};
