import React from 'react';
import styles from './MessageWithIconSection.module.scss';

type MessageWithIconSectionProps = {
  icon: () => JSX.Element;
  children: JSX.Element | Array<JSX.Element>;
};

export const MessageWithIconSection = ({ icon, children }: MessageWithIconSectionProps): JSX.Element => {
  return (
    <section>
      <div className={styles.icon}>{icon()}</div>
      <div className={styles.message}>{children}</div>
    </section>
  );
};
