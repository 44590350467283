import jwtDecode from 'jwt-decode';

const TOKEN_KEY_STORE = 'jwt_key';

export type Token = {
  access_token: string;
  expired_in: number;
  refresh_token: string;
  token_type: string;
  scope: string;
};

class TokenService {
  get token(): Token {
    return JSON.parse(localStorage.getItem(TOKEN_KEY_STORE) as string) as Token;
  }
  set token(token: Token) {
    localStorage.setItem(TOKEN_KEY_STORE, JSON.stringify(token));
  }
  get info() {
    return jwtDecode(this.token.access_token);
  }
  clear() {
    localStorage.removeItem(TOKEN_KEY_STORE);
  }
}

export const tokenService = new TokenService();
