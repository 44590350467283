import { AxiosInstance } from 'axios';
import { docclokerApiAxiosInstance } from '../../core/axios';
import { tokenService } from '../../core/services/token.service';
import { LOGIN_URL } from '../constants/LoginApiUrl.constants';
import { AuthLoginRequest, AuthTokenResponse, UserData } from '../types/authServices.types';

const DATA_AUTH_KEY_STORE = 'data_auth_key';
class AuthService {
  apiInstance: AxiosInstance;
  constructor(axiosInstance: AxiosInstance) {
    this.apiInstance = axiosInstance;
  }

  isUserLoggedIn(): boolean {
    return !!tokenService.token;
  }

  logIn(data: AuthLoginRequest): Promise<AuthTokenResponse> {
    this.dataStorage = data;
    return this.apiInstance
      .post(LOGIN_URL, data)
      .then((response: { data: AuthTokenResponse }) => {
        tokenService.token = response.data;
        return response.data;
      })
      .catch((reason) => Promise.reject(reason));
  }
  isSurrogate(): boolean {
    return tokenService.token && tokenService.token.scope.includes('surrogate');
  }
  user(): UserData {
    return tokenService.info as UserData;
  }
  get dataStorage(): AuthLoginRequest {
    return JSON.parse(localStorage.getItem(DATA_AUTH_KEY_STORE) as string) as AuthLoginRequest;
  }
  set dataStorage(authLoginRequest: AuthLoginRequest) {
    localStorage.setItem(DATA_AUTH_KEY_STORE, JSON.stringify(authLoginRequest));
  }
}

export const authService = new AuthService(docclokerApiAxiosInstance);
