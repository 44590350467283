const {
  REACT_APP_API_URL,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_GOOGLE_TRANSLATE_API_URL,
  REACT_APP_WEBSOCKET_URL
} = process.env;

export const docclokerAppConfig = {
  API_URL: REACT_APP_API_URL || '',
  GOOGLE_API_KEY: REACT_APP_GOOGLE_API_KEY || '',
  GOOGLE_TRANSLATE_API_URL: REACT_APP_GOOGLE_TRANSLATE_API_URL || '',
  WEBSOCKET_URL: REACT_APP_WEBSOCKET_URL || ''
};
