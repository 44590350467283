import * as types from './chat-room.action-types';
import { Message } from '../types/Message.type';
import { ChatRoomStoreTypes } from './chat-room.store-types';
import { ChatStatus } from '../types/ChatStatus.type';
import { UserStatus } from '../types/UserStatus.type';

export interface ChatRoomReducerState {
  messages: Message[];
  patientCode: string;
  userStatus: UserStatus;
  chatStatus: ChatStatus;
  chatImage: string;
  canManageUsers: boolean;
  operationID: string;
  patientName: string;
}

const initialState = {
  messages: [],
  chatStatus: ChatStatus.Join,
  userStatus: UserStatus.Waiting,
  canManageUsers: false,
  patientCode: '',
  patientName: '',
  operationID: '',
  chatImage: ''
};

export function ChatRoomReducer(
  state: ChatRoomReducerState = initialState,
  action: ChatRoomStoreTypes
): ChatRoomReducerState {
  switch (action.type) {
    case types.SET_NEW_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.message]
      };
    case types.SET_PATIENT_CODE:
      return {
        ...state,
        patientCode: action.patientCode
      };
    case types.SET_USER_STATUS:
      return {
        ...state,
        userStatus: action.status
      };
    case types.SET_CHAT_STATUS:
      return {
        ...state,
        chatStatus: action.status
      };
    case types.SET_OPERATION_ID:
      return {
        ...state,
        operationID: action.operationID
      };
    case types.SET_PATIENT_NAME:
      return {
        ...state,
        patientName: action.patientName
      };
    case types.SET_CHAT_IMAGE:
      return {
        ...state,
        chatImage: action.imageUrl
      };
    case types.UPDATE_MESSAGES:
      return {
        ...state,
        messages: action.messages
      };
    default:
      return state;
  }
}
