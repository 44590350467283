import React from 'react';
import { AppProviders } from './App.providers';
import { AppRoutes } from './App.routes';

export const App = (): JSX.Element => {
  return (
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  );
};
