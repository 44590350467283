import React from 'react';

export const LockIcon = (): JSX.Element => {
  return (
    <svg width="20" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0C6.156 0 3 3.156 3 7v3H0v16h20V10h-3V7c0-3.844-3.156-7-7-7zm0 2c2.754 0 5 2.246 5 5v3H5V7c0-2.754 2.246-5 5-5zM2 12h16v12H2V12z"
        fill="currentColor"
      />
    </svg>
  );
};
