import React, { ChangeEvent, useState } from 'react';
import { RadioInput } from '../shared/components/RadioInput/RadioInput.component';
import { Button } from '../shared/components/Button/Button.component';
import { LANGUAGE_KEY_STORE, LANGUAGES } from './constants/languages.constants';
import styles from './SelectLanguage.module.scss';
import { useTranslation } from 'react-i18next';
import { InterfaceWrapper } from '../shared/components/InterfaceWrapper/InterfaceWrapper.component';

type SelectLanguageProps = {
  languageSet?: (language: string) => void;
};
export const SelectLanguage = ({ languageSet }: SelectLanguageProps): JSX.Element => {
  // Translation module
  const { t, i18n } = useTranslation();
  // Local
  const [language, setLanguage] = useState('');
  const handleChangeLanguage = (event: ChangeEvent<HTMLInputElement>) => {
    setLanguage(event.target.value);
  };
  const handleClick = () => {
    localStorage.setItem(LANGUAGE_KEY_STORE, language);
    i18n.changeLanguage(language).then(() => {
      languageSet && languageSet(language);
    });
  };

  return (
    <InterfaceWrapper title={t('select_language.title')} condenseMobileVertical={true}>
      <div className="d-flex align-items-center flex-column">
        <div className="col-12 px-0">
          {LANGUAGES.map((lang) => (
            <div className={styles.RadioContainer} key={lang.id}>
              <RadioInput
                id={lang.id}
                value={lang.id}
                onChange={handleChangeLanguage}
                isChecked={language === lang.id}
                name="languageSelector"
              >
                {lang.img}
                <span className="ml-2">{lang.text}</span>
              </RadioInput>
            </div>
          ))}
        </div>
        <Button
          backgroundGlobal={language ? 'success' : 'secondary'}
          text={t('select_language.button')}
          className={styles.Button}
          disabled={!language}
          onClick={handleClick}
        />
      </div>
    </InterfaceWrapper>
  );
};
