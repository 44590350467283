import React from 'react';
import logo from '../../../../logo.svg';
import styles from './FooterLayout.module.scss';

export const FooterLayout = (): JSX.Element => {
  return (
    <footer className={styles.AppFooter + ' bg-gradient-primary d-sm-none'}>
      <img src={logo} alt="logo" width="60%" className="img-fluid" />
    </footer>
  );
};
