import React from 'react';
import styles from './SimpleFooterLayout.module.scss';
import { useTranslation } from 'react-i18next';

export const SimpleFooterLayout = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <footer className={styles.SimpleFooter}>
      <i>
        © {t('app.name')} 2021 <a href="#">{t('terms-and-conditions.title')}</a>|
        <a href="#">{t('privacy_policy.title')}</a>|<a href="#">{t('language.title')}</a>
      </i>
    </footer>
  );
};
