import React, { useEffect } from 'react';
import { HeaderLayout } from '../../../shared/components/HeaderLayout/HeaderLayout.component';
import { HeaderContent } from '../../../shared/components/HeaderContent/HeaderContent.component';
import { ContentBox } from '../../../shared/components/ContentBox/ContentBox.component';
import { FooterLayout } from '../../../shared/components/FooterLayout/FooterLayout.component';
import { SimpleFooterLayout } from '../../../shared/components/SimpleFooterLayout/SimpleFooterLayout.component';
import styles from './InterfaceWrapper.module.scss';

type InterfaceWrapperProps = {
  title?: string;
  wellSize?: string;
  footer?: 'full' | 'simple' | 'none';
  forceNavbarMobile?: boolean;
  includeWell?: boolean;
  condenseMobileVertical?: boolean;
  children: JSX.Element | Array<JSX.Element>;
};

export const InterfaceWrapper = ({
  title,
  wellSize = 'regular',
  forceNavbarMobile = false,
  footer = 'full',
  includeWell = true,
  condenseMobileVertical = false,
  children
}: InterfaceWrapperProps): JSX.Element => {
  const includeFooter = footer === 'full';
  const includeSimpleFooter = footer === 'simple';

  useEffect(() => {
    const setRealVh = () => {
      document.documentElement.style.setProperty('--viewportHeight', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', () => {
      setRealVh();
    });
    setRealVh();
  }, []);

  return (
    <main className={styles.ContentContainer}>
      <HeaderLayout forceNavbarMobile={forceNavbarMobile} />
      <ContentBox forceNavbarMobile={forceNavbarMobile} footer={footer}>
        <>{title && <HeaderContent title={title} />}</>
        <div
          className={`row align-items-center flex-column
          ${styles.wellWrapper}
          ${!title ? styles.wellWrapper__hasNoTitle : ''}
          `}
        >
          {includeWell ? (
            <div
              className={`col-12 col-lg-6 ${styles.well} ${styles[`well__${wellSize}`]} ${
                condenseMobileVertical ? styles.condenseMobileVertical : ''
              }`}
            >
              {children}
            </div>
          ) : (
            <>{children}</>
          )}
        </div>
        <>{includeFooter && <FooterLayout />}</>
        <>{includeSimpleFooter && <SimpleFooterLayout />}</>
      </ContentBox>
    </main>
  );
};
