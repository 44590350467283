import React, { MouseEventHandler } from 'react';
import styles from './Button.module.scss';

type ButtonProps = {
  children?: string | JSX.Element | Array<JSX.Element>;
  text?: string | JSX.Element | Array<JSX.Element>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  colorDesktop?: string;
  colorGlobal?: string;
  hoverColorDesktop?: string;
  hoverColorGlobal?: string;
  backgroundDesktop?: string;
  backgroundGlobal?: string;
  hoverBackgroundDesktop?: string;
  hoverBackgroundGlobal?: string;
  styleDesktop?: string;
  styleGlobal?: string;
};

export const Button = ({
  text,
  children,
  onClick,
  className,
  disabled,
  type,
  hoverColorDesktop,
  hoverColorGlobal,
  colorDesktop,
  colorGlobal = 'light',
  hoverBackgroundDesktop,
  hoverBackgroundGlobal,
  backgroundDesktop,
  backgroundGlobal = 'primary',
  styleDesktop,
  styleGlobal = 'pill'
}: ButtonProps): JSX.Element => {
  colorDesktop = colorDesktop ?? colorGlobal;
  hoverColorDesktop = hoverColorDesktop ?? colorDesktop;
  backgroundDesktop = backgroundDesktop ?? backgroundGlobal;
  hoverBackgroundDesktop = hoverBackgroundDesktop ?? backgroundDesktop;
  styleDesktop = styleDesktop ?? styleGlobal;

  hoverColorGlobal = hoverColorGlobal ?? colorGlobal;
  hoverBackgroundGlobal = hoverBackgroundGlobal ?? backgroundGlobal;

  return (
    <button
      type={type}
      onClick={onClick}
      className={[
        styles.Button,
        colorDesktop && styles['Button__Color' + colorDesktop + 'Desktop'],
        colorGlobal && styles['Button__Color' + colorGlobal + 'Global'],
        hoverColorDesktop && styles['Button__HoverColor' + hoverColorDesktop + 'Desktop'],
        hoverColorGlobal && styles['Button__HoverColor' + hoverColorGlobal + 'Global'],
        backgroundDesktop && styles['Button__Background' + backgroundDesktop + 'Desktop'],
        backgroundGlobal && styles['Button__Background' + backgroundGlobal + 'Global'],
        hoverBackgroundDesktop && styles['Button__HoverBackground' + hoverBackgroundDesktop + 'Desktop'],
        hoverBackgroundGlobal && styles['Button__HoverBackground' + hoverBackgroundGlobal + 'Global'],
        styleDesktop && styles['Button__Style' + styleDesktop[0].toUpperCase() + styleDesktop.slice(1) + 'Desktop'],
        styleGlobal && styles['Button__Style' + styleGlobal[0].toUpperCase() + styleGlobal.slice(1) + 'Global'],
        className
      ].join(' ')}
      disabled={disabled}
    >
      {text || children}
    </button>
  );
};

Button.defaultProps = {
  type: 'button'
};
