import React, { useEffect, useRef, useState } from 'react';
import styles from './Dropdown.module.scss';
import { ChevronDownIcon } from '../svg/ChevronDownIcon.component';

export type DropdownItem = {
  id: string;
  text: string | JSX.Element | Array<JSX.Element>;
  onClick?: (data: unknown) => void | boolean;
  active?: boolean;
  data?: unknown;
};

type DropdownProps = {
  text?: string | JSX.Element | Array<JSX.Element>;
  children?: string | JSX.Element | Array<JSX.Element>;
  className?: string;
  options: DropdownItem[];
  right?: boolean;
};

export const Dropdown = ({ text, options, children, right, className }: DropdownProps): JSX.Element => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const toggleDropdownMenu = () => {
    setShow(!show);
  };

  useEffect(
    function closeByOutsideClick() {
      const checkOutsideClick = (event: MouseEvent) => {
        const container = dropdownRef;
        const clickInside = container && container.current && container.current.contains(event.target as Node);
        if (!clickInside) {
          setShow(false);
        }
      };
      if (show) {
        document.addEventListener('mousedown', checkOutsideClick);
      }
      return () => {
        if (show) {
          document.removeEventListener('mousedown', checkOutsideClick);
        }
      };
    },
    [dropdownRef, show]
  );

  const items = options.map((option: DropdownItem) => (
    <li key={option.id} className={option.active ? styles.DropdownMenuActive : ''}>
      <button
        className={styles.DropdownOption}
        onClick={() => {
          option.onClick && option.onClick(option.data);
          setShow(false);
        }}
      >
        {option.text}
      </button>
    </li>
  ));

  return (
    <div className={`${styles.DropdownContainer} ${show && styles.show} ${className}`} ref={dropdownRef}>
      <button className={styles.Activator} onClick={toggleDropdownMenu}>
        {text || children}
        <div className={styles.Chevron}>
          <ChevronDownIcon />
        </div>
      </button>
      {show && <ul className={`${styles.DropdownMenu} ${right && styles.right}`}>{items}</ul>}
    </div>
  );
};
