import React, { useState } from 'react';
import styles from './TermsAndConditionsModal.module.scss';
import { CloseButton } from '../../../shared/components/CloseButton/CloseButton.component';
import { Button } from '../../../shared/components/Button/Button.component';
import { useTranslation } from 'react-i18next';

type TermsAndConditionsModalProps = {
  title: string;
  modalIsOpen?: boolean;
  onCloseClick: React.MouseEventHandler<HTMLButtonElement>;
  onControlClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  children?: JSX.Element | Array<JSX.Element>;
};

export const TermsAndConditionsModal = ({
  title,
  modalIsOpen,
  onControlClick,
  onCloseClick,
  children
}: TermsAndConditionsModalProps): JSX.Element => {
  const { t } = useTranslation();

  const [bottomOfContent, setBottomOfContent] = useState(true);

  const hasUserScrolledToBottomOfContent = (e: React.SyntheticEvent): void => {
    const element = e.target as HTMLInputElement;
    const scrollPadding = 20;

    if (element.scrollHeight - Math.round(element.scrollTop) - scrollPadding <= element.clientHeight) {
      setBottomOfContent(false);
    } else {
      setBottomOfContent(true);
    }
  };

  return (
    <div className={styles.standardModal} aria-hidden={!modalIsOpen}>
      <div className={[styles.standardModalContainer, 'modal-drop-shadow'].join(' ')}>
        <div className={styles.standardModal_close}>
          <CloseButton disabled={true} onClick={onCloseClick} />
        </div>
        <div className={styles.standardModal_toolbar}>
          <h2 className={styles.standardModal_toolbarTitle + ' font-modal-title'}>{title}</h2>
        </div>
        <div className={styles.standardModal_content} onScroll={hasUserScrolledToBottomOfContent}>
          {children}
        </div>
        <div className={styles.standardModal_actions}>
          {onControlClick && (
            <Button backgroundGlobal={!bottomOfContent ? 'success' : 'success'} onClick={onControlClick}>
              {t('terms-and-conditions.modal-accept-button', 'I have read Terms and Conditions')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
