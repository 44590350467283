import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InterfaceWrapper } from '../shared/components/InterfaceWrapper/InterfaceWrapper.component';
import { MessageWithIconSection } from '../shared/components/MessageWithIconSection/MessageWithIconSection.component';
import { LockIcon } from '../shared/components/svg/LockIcon.component';

export const Home = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <InterfaceWrapper includeWell title={t('home.title', 'Secure Chat - Welcome')}>
      <MessageWithIconSection icon={LockIcon}>
        <Trans i18nKey="home.section">
          <p>You need to be invited to join a session.</p>
        </Trans>
      </MessageWithIconSection>
    </InterfaceWrapper>
  );
};
