import * as types from './members.action-types';
import { MembersStoreTypes } from './members.store-types';
import { Member } from '../types/Member.type';

export interface MembersReducerState {
  members: Member[];
  newMemberNotify: boolean;
}

const initialState = {
  members: [],
  newMemberNotify: false
};

export function MembersReducer(
  state: MembersReducerState = initialState,
  action: MembersStoreTypes
): MembersReducerState {
  switch (action.type) {
    case types.SET_MEMBER_LIST:
      return {
        ...state,
        members: action.members
      };
    case types.SET_NEW_MEMBER_NOTIFY:
      return {
        ...state,
        newMemberNotify: action.newMemberNotify
      };
    default:
      return state;
  }
}
