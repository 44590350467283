import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Routes } from './modules/core/constants/routes.constants';

export const AppRoutes = (): JSX.Element => {
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <Switch>
        {Routes.map((route) => (
          <Route {...route} key={route.key} />
        ))}
      </Switch>
    </Suspense>
  );
};
