/* eslint react-hooks/exhaustive-deps: off */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Input.module.scss';

type InputProps = {
  inputName: string;
  label: string;
  state: string;
  onInputChange: React.ChangeEventHandler<HTMLInputElement>;
  handleError: (origin: string, add?: boolean) => void;
  disabled?: boolean;
};

export const Input = ({ inputName, label, state, handleError, onInputChange, disabled }: InputProps): JSX.Element => {
  const { t } = useTranslation();

  const [error, setError] = useState('');

  useEffect(
    function validateInput() {
      if (!state || !state.trim() || state.length === 0) {
        setError(t('input.text.error.empty', { label }));
        handleError(inputName);
      } else {
        setError('');
        handleError(inputName, false);
      }
    },
    [state]
  );

  useEffect(function initInputWithErrorStateUntilChangeEvent() {
    setError('');
    handleError(inputName);
  }, []);

  return (
    <div className={[styles.input_container, error ? styles.input_container__errorState : ''].join(' ')}>
      <div
        className={[styles.input, disabled ? styles.input__disabled : '', state === '' ? styles.input__empty : ''].join(
          ' '
        )}
      >
        <input
          className={[
            styles.input_realInput,
            'font-form-input',
            error ? 'input-drop-shadow--error' : 'input-drop-shadow'
          ].join(' ')}
          type="text"
          name={inputName}
          id={inputName}
          value={state}
          onChange={onInputChange}
        />
        <label htmlFor={inputName} className={[styles.input_label, 'font-form-input'].join(' ')}>
          {label}
        </label>
      </div>
      {error && <span className={styles.input_error}>{error}</span>}
    </div>
  );
};
