import React from 'react';

export const ChineseFlag = (): JSX.Element => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.295 7.597C1.622 2.235 7.04-1.03 12.403.295c5.362 1.327 8.628 6.745 7.302 12.108-1.327 5.357-6.745 8.629-12.108 7.302C2.24 18.378-1.032 12.96.295 7.597z"
        fill="#FF1507"
      />
      <path
        d="M10.636 9.678l-.466.568-.679-.274.39.618-.466.562.709-.182.39.617.046-.729.708-.182-.678-.273.046-.725zm2.709-1.534h-.735l-.223-.699-.227.7h-.73l.593.43-.228.698.592-.43.593.43-.228-.698.593-.43zM12.149 5.46l.112.724.334-.653.724.117-.516-.517.334-.653-.653.33-.517-.517.112.724-.654.329.724.116zm-1.508-3.352l-.466.562-.679-.268.39.618-.466.562.71-.183.389.618.046-.729.709-.182-.679-.269.045-.729zm-5.115 3.22l-.689-2.116-.683 2.117H1.926l1.802 1.306-.688 2.117 1.803-1.307L6.64 8.752l-.689-2.117L7.754 5.33H5.526z"
        fill="#FFD606"
      />
    </svg>
  );
};
