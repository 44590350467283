import React from 'react';

export const PortugueseFlag = (): JSX.Element => {
  return (
    <svg width="20" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.39.297A10.023 10.023 0 009.157.033v19.966c4.814.406 9.349-2.734 10.546-7.573 1.329-5.372-1.943-10.8-7.315-12.13z"
        fill="#FF1507"
      />
      <path
        d="M9.209.028C5.044.373 1.356 3.33.296 7.612c-1.33 5.367 1.948 10.8 7.315 12.129.527.131 1.085.187 1.608.233L9.209.028z"
        fill="#138F34"
      />
      <path
        d="M14.793 14.714C12.627 14.648 2.71 8.454 2.64 7.47l.548-.913c.984 1.43 11.13 7.451 12.124 7.239l-.518.918z"
        fill="#FFE831"
      />
      <path
        d="M14.793 14.698C12.649 14.642 2.71 8.428 2.66 7.47h-.015l.015.01.548-.913-.015-.01-.015.01c.979 1.415 10.78 7.249 12.078 7.249l.061-.006v-.015l-.015-.01-.518.918.03.016.518-.919.02-.035-.035.005-.056.005c-1.268.005-11.094-5.839-12.048-7.239l-.015-.02-.558.938v.01c.086 1.015 9.988 7.189 12.17 7.265v-.03h-.015z"
        fill="#1E1E1E"
      />
      <path
        d="M14.844 14.739l-.233.056c-.807-.091-3.267-1.284-6.169-3.024C5.064 9.747 2.27 7.906 2.568 7.424l.086-.147.016.005c-.274.817 5.514 4.124 5.854 4.337 3.343 2.075 6.168 3.282 6.417 2.968l-.097.152zM3.07 6.429c-.192.523 2.593 2.243 5.94 4.277 3.349 2.04 6.24 3.297 6.448 3.115l.096-.183-.289.036c-.903-.264-3.261-1.345-6.189-3.115C6.155 8.784 3.613 7.155 3.217 6.46l-.045-.203h-.01l-.087.147-.005.026z"
        fill="#FFE831"
      />
      <path
        d="M14.844 14.739l-.005-.02-.233.055c-.791-.086-3.252-1.283-6.158-3.023-3.211-1.917-5.895-3.693-5.885-4.25l.02-.072.077-.137.005-.02-.02-.005-.01.066c.01.938 5.539 4.078 5.868 4.291 2.993 1.857 5.56 3.018 6.26 3.018l.178-.055-.015-.01-.016-.01-.091.157.025.015h.02v-.02l-.02.005v.015h-.015v.066l.122-.213-.03-.02-.153.04c-.68 0-3.256-1.162-6.244-3.013-.33-.203-5.864-3.379-5.854-4.261l.01-.051.005-.015-.036-.01h-.015l-.091.157-.025.091c.01.609 2.688 2.354 5.904 4.282 2.907 1.74 5.362 2.937 6.179 3.028l.243-.056v-.025h-.015.015zM3.071 6.429l-.016-.004-.01.06c.01.61 2.714 2.263 5.956 4.241 3.14 1.913 5.864 3.13 6.386 3.135l.086-.02.102-.188.015-.03-.325.04c-.892-.258-3.251-1.339-6.173-3.114C6.17 8.779 3.623 7.145 3.238 6.46l-.04-.198-.006-.015h-.03l-.086.157-.016.02.01.006.016.01.015-.02.086-.148-.015-.01v.016h.01v-.016l-.02.005.045.203c.406.705 2.943 2.334 5.87 4.11 2.922 1.775 5.28 2.855 6.188 3.119l.264-.03-.081.152-.056.01c-.492 0-3.231-1.223-6.366-3.13-3.236-1.963-5.95-3.652-5.94-4.205l.005-.051-.02-.015z"
        fill="#1E1E1E"
      />
      <path
        d="M9.082 15.134c2.612.016 4.89-.37 5.93-.908l.38-.664c-.903.492-3.363 1.01-6.305.928-2.4-.015-4.961-.264-6.189-.959l.558.772c.994.547 3.378.826 5.626.831z"
        fill="#FFE831"
      />
      <path
        d="M9.082 15.15h.132c2.556 0 4.778-.38 5.808-.913l.386-.67.03-.05-.05.03c-.838.456-3.03.938-5.703.933l-.593-.005c-2.4-.015-4.956-.264-6.179-.959l-.02.026.558.77c1 .559 3.383.838 5.63.838v-.036c-2.241 0-4.626-.279-5.62-.832l-.553-.766-.015.01-.01.016c1.232.695 3.794.948 6.199.963l.593.006c2.679 0 4.87-.477 5.722-.939l-.01-.015-.015-.01-.38.664c-1.01.523-3.232.903-5.789.903h-.131v.036h.01z"
        fill="#1E1E1E"
      />
      <path
        d="M9.117 15.19c-1.506-.005-2.896-.096-3.977-.294-.781-.157-1.304-.345-1.689-.548l-.147-.223c.279.183.837.416 1.862.609 1.075.198 2.455.289 3.951.294 1.426-.01 2.77-.086 3.86-.279.934-.167 1.99-.467 2.268-.781l-.162.264c-.315.238-1.167.502-2.034.674-1.101.208-2.506.29-3.932.284zm0-.608c-1.659-.01-2.977-.071-4.14-.29a7.872 7.872 0 01-2.206-.76l-.07-.148c.202.153 1.12.528 2.302.751 1.121.198 2.43.29 4.11.284 1.48 0 2.916-.066 4.052-.284 1.127-.218 2.01-.477 2.364-.71l-.111.203c-.142.162-1.162.462-2.227.665-1.147.208-2.587.294-4.074.289z"
        fill="#FFE831"
      />
      <path
        d="M9.117 15.175c-1.506-.005-2.891-.096-3.972-.294-.781-.157-1.304-.34-1.684-.543l-.137-.213-.015.01-.01.015c.284.183.842.416 1.866.614 1.076.198 2.46.29 3.957.294 1.426-.01 2.775-.086 3.86-.279.934-.172 1.99-.461 2.278-.786l-.015-.01-.015-.01-.162.264c-.305.228-1.157.497-2.025.664-1.08.198-2.46.28-3.865.28h-.06v.035h.055c1.405 0 2.79-.082 3.876-.28.867-.172 1.72-.436 2.044-.68l.167-.268-.03-.02c-.27.31-1.324.608-2.258.776-1.09.193-2.434.264-3.86.279-1.496-.005-2.876-.096-3.947-.294-1.02-.198-1.577-.431-1.856-.609l-.026.025.148.223c.395.208.918.401 1.704.553 1.08.198 2.476.29 3.982.295v-.04zm0-.593v-.016c-1.659-.01-2.978-.07-4.14-.289a7.886 7.886 0 01-2.2-.76l-.062-.138-.015.005-.01.016c.208.157 1.126.532 2.313.755 1.096.193 2.37.285 3.992.285h.193c1.456 0 2.866-.072 3.987-.284 1.132-.219 2.01-.477 2.37-.716l-.01-.015-.016-.01-.112.203c-.127.152-1.151.456-2.211.654-1.132.208-2.547.29-4.013.29h-.056v.035h.056c1.471 0 2.886-.086 4.018-.29 1.07-.202 2.08-.497 2.237-.669l.117-.203-.026-.025c-.35.233-1.227.492-2.354.71-1.116.213-2.526.284-3.982.284H9c-1.623 0-2.891-.086-3.987-.284-1.182-.223-2.1-.599-2.293-.746l-.025.02.071.148a7.695 7.695 0 002.222.77c1.162.214 2.48.28 4.14.29v-.02h-.01z"
        fill="#1E1E1E"
      />
      <path
        d="M9.163 11.72c2.415-.02 5.418-.34 7.127-1.04v-.608c-1.014.573-4.398.949-7.147 1.004-3.252-.03-5.895-.471-7.046-1.263l-.016.787c2.11.918 4.297 1.11 7.082 1.12z"
        fill="#FFE831"
      />
      <path
        d="M9.163 11.736c2.415-.02 5.418-.34 7.137-1.04l.01-.005v-.654l-.025.015c-1.004.568-4.393.948-7.142 1.004-3.252-.03-5.895-.472-7.041-1.258l-.026-.02-.02.822v.01l.01.005c2.126.918 4.312 1.11 7.097 1.12V11.7c-2.785-.01-4.966-.202-7.077-1.116l-.005.016h.015l.016-.787h-.016l-.01.016c1.162.79 3.805 1.232 7.057 1.263 2.749-.056 6.138-.431 7.157-1.01l-.01-.015h-.015v.609h.015l-.005-.015c-1.71.694-4.713 1.014-7.122 1.034v.04z"
        fill="#1E1E1E"
      />
      <path
        d="M16.376 10.696c-.06.096-1.182.487-2.825.781a28.533 28.533 0 01-4.393.325c-1.73 0-3.14-.122-4.206-.274-1.73-.28-2.622-.665-2.952-.802v-.172c.949.39 1.928.624 2.978.786a29.55 29.55 0 004.175.274c1.81 0 3.251-.162 4.362-.33 1.69-.279 2.73-.639 2.861-.801v.213z"
        fill="#FFE831"
      />
      <path
        d="M16.376 10.696l-.015-.01c-.04.081-1.172.487-2.815.77a28.5 28.5 0 01-4.388.325h-.046a30.15 30.15 0 01-4.16-.273c-1.724-.28-2.617-.66-2.947-.797L2 10.726h.015v-.172H2l-.005.015c.954.39 1.928.624 2.983.786a29.24 29.24 0 004.088.274h.087c1.81 0 3.251-.162 4.362-.33 1.694-.278 2.73-.633 2.871-.811l-.015-.01h-.02v.208h.025l-.015-.01.015.02h.015v-.258l-.03.04c-.117.157-1.167.522-2.85.796-1.112.168-2.552.33-4.363.33H9.06c-1.679 0-3.043-.127-4.083-.274-1.05-.162-2.03-.396-2.973-.781l-.025-.01V10.746l.01.006c.33.137 1.227.522 2.957.801 1.06.147 2.456.274 4.165.274h.046c1.816 0 3.277-.152 4.393-.325 1.653-.294 2.764-.674 2.84-.79V10.7h-.015v-.005z"
        fill="#1E1E1E"
      />
      <path
        d="M16.376 10.077c-.182.152-1.425.482-3.023.746a28.28 28.28 0 01-4.175.294c-1.654.005-3.008-.112-4.033-.264C3.517 10.635 2 9.905 2 9.905v-.239s1.512.771 3.165 1.01c1.02.147 2.37.258 4.013.253 1.73 0 3.08-.142 4.144-.299 1.614-.228 2.927-.65 3.06-.75v.197h-.006zm0 .619c-.06.096-1.182.487-2.825.781a28.518 28.518 0 01-4.393.325c-1.73 0-3.14-.122-4.206-.274-1.73-.279-2.622-.665-2.952-.802v-.172c.949.39 1.928.624 2.978.786a29.55 29.55 0 004.175.274c1.81 0 3.251-.162 4.362-.33 1.69-.279 2.73-.639 2.861-.801v.213z"
        fill="#FFE831"
      />
      <path
        d="M16.376 10.077l-.01-.015c-.172.142-1.42.477-3.018.74a28.26 28.26 0 01-4.17.295h-.091c-1.608 0-2.932-.112-3.937-.264-1.623-.218-3.135-.949-3.14-.949l-.005.016h.016V9.66h-.016l-.01.015s1.517.777 3.17 1.015c1.01.142 2.344.253 3.968.253h.05c1.73 0 3.08-.142 4.145-.299 1.618-.228 2.922-.644 3.064-.756l-.01-.015h-.02v.198h.014l-.01-.015.01.02h.016v-.233l-.03.025c-.122.096-1.441.523-3.05.746-1.065.157-2.41.3-4.139.3h-.05a28.62 28.62 0 01-3.962-.255C3.51 10.422 2.005 9.651 2 9.651l-.026-.015v.279l.01.005s1.517.73 3.15.954c1.01.152 2.329.263 3.942.263h.091a29 29 0 004.175-.294c1.603-.264 2.84-.594 3.033-.746l.005-.005v-.01h-.005v-.005zm0 .619l-.015-.01c-.04.081-1.172.487-2.815.77a28.512 28.512 0 01-4.388.325h-.046a30.15 30.15 0 01-4.16-.273c-1.724-.28-2.617-.66-2.947-.797L2 10.726h.015v-.172H2l-.005.015c.954.39 1.933.624 2.983.786 1.045.147 2.41.274 4.089.274h.086c1.81 0 3.251-.162 4.362-.33 1.695-.278 2.73-.633 2.872-.811l-.016-.01h-.02v.208h.025l-.015-.01.015.02h.016v-.258l-.03.04c-.117.157-1.167.522-2.852.796-1.11.168-2.551.33-4.362.33h-.086c-1.68 0-3.044-.127-4.084-.274-1.045-.162-2.03-.396-2.973-.781l-.025-.01V10.746l.01.006c.33.137 1.228.522 2.957.801 1.06.147 2.456.274 4.165.274h.046c1.816 0 3.277-.152 4.393-.325 1.654-.294 2.764-.68 2.84-.79V10.7h-.015v-.005z"
        fill="#1E1E1E"
      />
      <path
        d="M9.198 8.063c2.166-.015 4.84-.294 6.377-.908l-.33-.537c-.908.502-3.596.831-6.062.882-2.917-.025-4.981-.3-6.011-.989l-.31.573c1.893.807 3.836.974 6.336.98z"
        fill="#FFE831"
      />
      <path
        d="M9.198 8.084c2.166-.016 4.84-.295 6.382-.908l.02-.006-.34-.558-.01-.015-.015.01c-.903.498-3.591.832-6.052.883-2.917-.025-4.976-.3-6-.99l-.016-.01-.32.59-.01.02.02.005c1.893.801 3.84.973 6.341.979v-.036c-2.5-.005-4.438-.177-6.335-.979l-.006.015.016.01.314-.573-.015-.01-.01.015c1.04.695 3.104.97 6.021.995 2.466-.051 5.154-.381 6.072-.888l-.01-.015-.015.01.33.537.015-.01-.005-.015c-1.532.609-4.206.888-6.367.903v.04h-.005z"
        fill="#1E1E1E"
      />
      <path
        d="M15.377 6.622c-.167.132-.984.421-2.425.65-.959.137-2.181.264-3.743.264-1.482 0-2.694-.097-3.617-.234-1.461-.193-2.243-.538-2.522-.634l.087-.142c.218.107.969.416 2.45.624.913.127 2.125.223 3.602.223 1.552 0 2.764-.127 3.713-.264 1.445-.197 2.227-.568 2.343-.659l.112.172zm.284.548c-.05.087-1.055.431-2.531.685-1 .152-2.308.284-3.942.284-1.547 0-2.815-.106-3.774-.238-1.552-.244-2.354-.579-2.648-.7l.071-.152c.852.34 1.659.542 2.597.69.954.126 2.207.238 3.744.238 1.623 0 2.917-.142 3.911-.294 1.512-.244 2.344-.558 2.455-.705l.117.192z"
        fill="#FFE831"
      />
      <path
        d="M15.367 6.612c-.157.122-.974.421-2.415.644-.959.143-2.181.264-3.738.264h-.051c-1.461 0-2.653-.101-3.566-.233-1.461-.193-2.237-.533-2.516-.634l-.005.015.015.01.086-.142-.015-.01-.01.015c.223.112.974.416 2.455.624.913.127 2.125.223 3.602.223 1.552 0 2.764-.126 3.718-.263 1.45-.198 2.227-.569 2.354-.665l-.01-.015-.016.01.107.178.03-.016-.106-.177-.01-.015-.016.015c-.111.091-.893.461-2.338.66-.954.136-2.156.263-3.713.263-1.477 0-2.689-.096-3.597-.223-1.481-.208-2.227-.518-2.445-.624l-.015-.005-.097.157-.01.015.02.005c.28.102 1.06.447 2.527.64.913.131 2.11.233 3.57.233h.052c1.562 0 2.785-.122 3.743-.264 1.441-.228 2.258-.522 2.435-.654l-.025-.03zm.294.558l-.015-.01c-.036.071-1.045.426-2.521.675-1 .152-2.308.284-3.937.284h-.045c-1.527 0-2.78-.112-3.724-.239-1.547-.243-2.348-.578-2.643-.695l-.005.016.015.005.071-.152-.015-.005-.005.015c.857.345 1.659.548 2.602.695.954.127 2.207.238 3.75.238a25.53 25.53 0 003.915-.294c1.517-.244 2.344-.553 2.47-.71l-.014-.01-.016.01.107.187.01-.01-.015-.01.015.01.015-.01-.106-.188-.015-.02-.016.02c-.106.137-.938.457-2.445.695-.994.147-2.287.29-3.91.295-1.543 0-2.79-.112-3.745-.239-.938-.147-1.74-.35-2.592-.69l-.015-.005-.076.168-.01.015.015.005c.294.122 1.096.456 2.653.7.949.127 2.197.238 3.729.238h.045c1.634 0 2.942-.132 3.942-.284 1.481-.258 2.475-.593 2.546-.695l.005-.01-.005-.01-.015.015z"
        fill="#1E1E1E"
      />
      <path
        d="M9.178 16.56a6.31 6.31 0 116.31-6.31 6.307 6.307 0 01-6.31 6.31zm0-13.296a6.984 6.984 0 00-6.98 6.986 6.98 6.98 0 1013.96 0 6.978 6.978 0 00-6.98-6.986z"
        fill="#FFE831"
      />
      <path
        d="M9.178 3.335c-3.815 0-6.914 3.095-6.914 6.91a6.911 6.911 0 006.914 6.909 6.911 6.911 0 006.914-6.91 6.912 6.912 0 00-6.914-6.909zm7.056 6.915a7.05 7.05 0 01-7.05 7.05 7.05 7.05 0 01-7.052-7.05 7.049 7.049 0 017.051-7.052 7.05 7.05 0 017.051 7.052z"
        fill="#FFE831"
      />
      <path
        d="M9.178 3.335v-.02a6.932 6.932 0 00-6.93 6.93 6.932 6.932 0 006.93 6.929 6.932 6.932 0 006.93-6.93 6.932 6.932 0 00-6.93-6.93V3.35a6.897 6.897 0 016.894 6.894 6.904 6.904 0 01-6.894 6.894 6.9 6.9 0 01-6.894-6.894C2.29 6.44 5.374 3.361 9.178 3.35v-.015zm7.056 6.914h-.02a7.042 7.042 0 01-7.036 7.036 7.042 7.042 0 01-7.036-7.036 7.043 7.043 0 017.036-7.036 7.042 7.042 0 017.036 7.036h.035a7.07 7.07 0 00-7.07-7.071 7.07 7.07 0 00-7.072 7.071 7.07 7.07 0 007.071 7.072 7.07 7.07 0 007.072-7.072h-.016z"
        fill="#1E1E1E"
      />
      <path
        d="M9.178 4.005a6.242 6.242 0 00-6.24 6.24 6.242 6.242 0 006.24 6.239 6.242 6.242 0 006.24-6.24 6.235 6.235 0 00-6.24-6.24zm6.382 6.244a6.38 6.38 0 01-6.382 6.382 6.383 6.383 0 01-6.381-6.382 6.38 6.38 0 016.381-6.381 6.374 6.374 0 016.382 6.381z"
        fill="#FFE831"
      />
      <path
        d="M9.178 4.005v-.02a6.263 6.263 0 00-6.26 6.26 6.26 6.26 0 106.26-6.26v.035a6.229 6.229 0 016.224 6.224 6.232 6.232 0 01-6.224 6.225 6.229 6.229 0 01-6.224-6.225A6.229 6.229 0 019.178 4.02v-.015zm6.381 6.244h-.015a6.37 6.37 0 01-6.36 6.367 6.37 6.37 0 01-6.362-6.367 6.37 6.37 0 016.361-6.366 6.37 6.37 0 016.361 6.366h.031a6.397 6.397 0 10-12.794 0 6.404 6.404 0 006.397 6.402 6.397 6.397 0 006.397-6.402h-.015z"
        fill="#1E1E1E"
      />
      <path
        d="M9.107 8.758c2.942-.015 6.011.421 7.183 1.035v.756c-1.02-.558-3.86-1.147-7.178-1.06-2.704.015-5.585.304-7.036 1.095v-.872c1.192-.63 4.5-.949 7.031-.954z"
        fill="#FFE831"
      />
      <path
        d="M9.107 8.773h.152c2.892 0 5.874.431 7.02 1.035l.011-.015h-.015v.756h.015l.01-.015c-.959-.523-3.495-1.07-6.534-1.07-.218 0-.436 0-.654.01-2.704.015-5.585.304-7.04 1.095l.01.015h.014v-.872h-.015l.01.015c1.177-.63 4.49-.949 7.016-.954v-.035c-2.531.005-5.839.324-7.04.953l-.011.005v.918l.025-.015c1.446-.786 4.327-1.075 7.026-1.095.223-.005.436-.01.654-.01 3.034 0 5.57.547 6.519 1.065l.025.015v-.796l-.01-.005c-1.162-.61-4.144-1.04-7.04-1.04h-.153v.04h.005z"
        fill="#1E1E1E"
      />
      <path
        d="M9.148 8.687c1.603-.005 3.19.086 4.428.315.974.192 2.45.497 2.8.766v.299c-.314-.36-1.826-.695-2.876-.888-1.227-.218-2.744-.304-4.352-.314-1.684.005-3.247.111-4.454.335-1.152.223-2.38.487-2.699.695V9.64c.436-.233 1.786-.452 2.673-.624 1.218-.218 2.785-.325 4.48-.33zm0 .695c1.679-.005 3.297.091 4.59.33 1.198.228 2.471.573 2.638.756v.228c-.4-.264-1.39-.563-2.663-.812-1.278-.243-2.901-.324-4.57-.32-1.893-.004-3.364.097-4.627.32-1.334.254-2.293.62-2.52.792v-.239c.446-.233 1.303-.512 2.485-.73 1.309-.239 2.795-.315 4.667-.325z"
        fill="#FFE831"
      />
      <path
        d="M9.148 8.707h.106c1.568 0 3.11.092 4.317.315.974.193 2.45.497 2.79.76l.01-.014h-.015v.299h.015l.016-.01c-.33-.366-1.837-.7-2.887-.893-1.227-.218-2.75-.304-4.352-.315-1.684.005-3.247.112-4.454.335-1.157.229-2.38.487-2.704.7L2 9.9h.015v-.253H2l.01.015c.432-.233 1.78-.446 2.664-.624 1.212-.223 2.78-.325 4.474-.33v-.035c-1.695.005-3.267.106-4.485.335-.887.172-2.237.39-2.678.629l-.01.005V9.94L2 9.925c.31-.208 1.542-.472 2.694-.695 1.207-.228 2.765-.33 4.449-.335 1.603.015 3.124.102 4.347.315 1.045.187 2.557.532 2.866.882l.03.036v-.36l-.005-.005c-.36-.28-1.83-.574-2.81-.772-1.207-.223-2.754-.314-4.322-.314h-.106v.03h.005zm0 .675v.02h.111c1.639 0 3.216.097 4.48.33 1.197.228 2.475.573 2.622.746l.015-.01h-.015v.228h.015l.01-.015c-.4-.27-1.394-.563-2.668-.812-1.248-.238-2.82-.32-4.443-.32h-.3c-1.81 0-3.231.102-4.459.32-1.339.259-2.293.619-2.526.796l.01.016h.015v-.239H2l.01.015c.442-.233 1.299-.512 2.48-.73 1.305-.243 2.791-.32 4.663-.33v-.03c-1.872.01-3.363.086-4.667.33-1.187.218-2.044.497-2.49.73l-.011.005V10.716l.03-.02c.224-.168 1.182-.538 2.511-.786C5.75 9.69 7.17 9.59 8.98 9.59h.3c1.623 0 3.196.086 4.438.32 1.268.248 2.263.542 2.658.806l.026.015v-.268l-.005-.005c-.178-.193-1.441-.533-2.648-.761-1.269-.234-2.846-.33-4.485-.33h-.111v.015h-.005z"
        fill="#1E1E1E"
      />
      <path
        d="M9.153 5.47c2.638-.014 4.94.371 5.996.909l.385.664c-.913-.492-3.398-1.009-6.376-.928-2.425.015-5.017.264-6.316.959l.457-.766c1.065-.553 3.581-.837 5.854-.837z"
        fill="#FFE831"
      />
      <path
        d="M9.153 5.486h.132c2.582 0 4.824.38 5.854.908l.375.66.015-.01.01-.016c-.857-.461-3.074-.938-5.783-.938l-.598.01c-2.425.015-5.017.264-6.326.964l.01.015.015.01.457-.766c1.05-.553 3.571-.832 5.839-.837v-.035c-2.273 0-4.789.284-5.864.837l-.467.776-.036.056.056-.03c1.294-.696 3.886-.944 6.306-.96l.598-.01c2.704 0 4.92.477 5.768.939l.056.03-.416-.72c-1.05-.538-3.293-.918-5.874-.918h-.132v.035h.005z"
        fill="#1E1E1E"
      />
      <path
        d="M9.183 5.41c1.44 0 2.861.076 3.972.28.873.172 1.74.435 2.055.674l.167.264c-.284-.315-1.35-.614-2.293-.782-1.1-.192-2.46-.263-3.9-.279-1.513 0-2.913.097-3.993.295-1.035.197-1.705.43-1.984.608l.142-.223c.391-.203 1.02-.39 1.816-.548 1.096-.192 2.501-.284 4.018-.289zm0 .609c1.507-.005 2.957.081 4.12.289 1.075.198 2.104.502 2.246.665l.117.197c-.36-.233-1.248-.492-2.39-.71-1.146-.218-2.601-.289-4.098-.284-1.7-.005-3.018.086-4.155.284-1.197.223-2.029.543-2.237.695l.112-.213c.4-.203 1.03-.451 2.095-.639 1.182-.213 2.516-.274 4.19-.284z"
        fill="#FFE831"
      />
      <path
        d="M9.183 5.425h.056c1.42 0 2.815.082 3.911.28.873.167 1.74.436 2.05.669l.162.259.015-.01.015-.01c-.294-.325-1.36-.62-2.303-.787-1.1-.193-2.465-.264-3.906-.279-1.511 0-2.912.097-3.997.294-1.035.198-1.705.432-1.989.614l.01.015.016.01.142-.223c.38-.192 1.01-.385 1.8-.537 1.096-.198 2.501-.29 4.018-.295V5.39c-1.522.005-2.927.096-4.017.294-.797.157-1.426.345-1.822.548l-.152.228.026.026c.279-.178.943-.411 1.978-.61 1.08-.197 2.48-.293 3.992-.293 1.44.01 2.8.086 3.901.279.939.162 2.009.466 2.283.776l.03-.02-.167-.264c-.33-.244-1.192-.508-2.07-.68-1.095-.198-2.495-.279-3.916-.279h-.056v.03h-.01zm0 .594v.02h.107c1.466 0 2.876.086 4.007.29 1.07.197 2.105.502 2.237.659l.112.198.015-.01.01-.016c-.365-.233-1.253-.492-2.394-.71-1.116-.208-2.521-.284-3.967-.284h-.304c-1.619 0-2.887.091-3.982.284-1.198.223-2.03.543-2.243.7l.01.015.016.01.111-.213c.39-.197 1.02-.44 2.08-.629 1.172-.213 2.501-.279 4.18-.289v-.04c-1.679.01-3.013.07-4.185.289-1.065.193-1.7.436-2.1.644l-.122.218.026.02c.203-.147 1.03-.471 2.227-.69C6.119 6.294 7.387 6.203 9 6.203h.304c1.45 0 2.85.07 3.962.284 1.141.218 2.029.476 2.384.705l.025-.026-.116-.197c-.163-.178-1.182-.472-2.263-.675-1.131-.203-2.546-.29-4.012-.29h-.107v.016h.005z"
        fill="#1E1E1E"
      />
      <path
        d="M9.183 5.41c1.44 0 2.861.076 3.972.28.873.172 1.74.435 2.055.674l.167.264c-.284-.315-1.35-.614-2.293-.782-1.096-.192-2.46-.263-3.9-.279-1.512 0-2.913.097-3.993.295-1.035.192-1.705.43-1.984.608l.143-.223c.39-.203 1.02-.39 1.816-.548 1.095-.192 2.5-.284 4.017-.289zm0 .609c1.507-.005 2.958.081 4.12.289 1.08.198 2.104.502 2.252.665l.116.197c-.36-.233-1.248-.492-2.389-.71-1.146-.218-2.602-.289-4.099-.284-1.7-.005-3.018.086-4.154.284-1.198.223-2.03.543-2.237.695l.111-.213c.4-.203 1.03-.451 2.095-.639 1.177-.213 2.511-.274 4.185-.284z"
        fill="#FFE831"
      />
      <path
        d="M9.183 5.41v.02h.056c1.42 0 2.815.081 3.911.28.873.167 1.74.436 2.05.669l.162.259.015-.01.015-.01c-.294-.325-1.36-.62-2.303-.787-1.095-.193-2.465-.264-3.906-.279-1.511 0-2.912.096-3.997.294-1.035.193-1.705.431-1.989.614l.01.015.016.01.142-.223c.38-.193 1.01-.385 1.8-.537 1.091-.198 2.496-.29 4.018-.295v-.035c-1.522.005-2.927.096-4.017.294-.797.157-1.426.345-1.821.548l-.153.228.026.025c.279-.177.943-.415 1.978-.608 1.08-.198 2.48-.294 3.992-.294 1.441.01 2.8.086 3.901.279.939.162 2.01.466 2.283.776l.03-.02-.167-.264c-.33-.244-1.192-.508-2.07-.68-1.095-.198-2.495-.28-3.916-.28h-.056v.011h-.01zm0 .609v.02h.107c1.466 0 2.876.086 4.007.29 1.076.197 2.105.501 2.242.659l.112.197.015-.01.01-.015c-.365-.233-1.253-.492-2.394-.71-1.116-.208-2.521-.284-3.967-.284h-.304c-1.618 0-2.887.091-3.982.284-1.197.223-2.03.543-2.242.7l.01.015.015.01.111-.213c.391-.198 1.02-.441 2.08-.629 1.172-.213 2.501-.279 4.18-.289v-.04c-1.679.01-3.013.07-4.185.289-1.065.192-1.7.436-2.1.644l-.122.218.026.02c.203-.147 1.03-.471 2.227-.69 1.095-.192 2.364-.284 3.977-.284h.304c1.45 0 2.851.071 3.962.284 1.141.219 2.029.477 2.384.706l.025-.026-.116-.198c-.162-.177-1.182-.471-2.263-.674-1.13-.203-2.541-.29-4.012-.29h-.107v.016z"
        fill="#1E1E1E"
      />
      <path
        d="M9.127 12.522c2.166.015 4.84.294 6.377.908l-.33.538c-.908-.502-3.596-.832-6.062-.883-2.922.026-4.94.325-5.95.893l-.28-.477c1.802-.801 3.745-.974 6.245-.979z"
        fill="#FFE831"
      />
      <path
        d="M9.127 12.537c2.161.015 4.835.294 6.367.903l.005-.015-.016-.01-.33.538.016.01.01-.016c-.918-.507-3.602-.837-6.072-.887-2.922.025-4.94.324-5.96.898l.01.015.015-.01-.28-.477-.014.01.005.015c1.806-.791 3.743-.964 6.244-.974v-.035c-2.5.005-4.449.177-6.26.984l-.015.005.29.497.01.015.015-.01c1.004-.568 3.023-.867 5.945-.893 2.465.051 5.154.38 6.052.883l.015.01.34-.558.01-.02-.02-.005c-1.542-.614-4.216-.893-6.382-.908v.035z"
        fill="#1E1E1E"
      />
      <path
        d="M15.002 14.064c-.295-.147-1.025-.426-2.207-.588a25.213 25.213 0 00-3.693-.264c-1.466 0-2.673.091-3.581.223-1.476.208-2.222.518-2.435.624L3 13.917c.279-.101 1.055-.446 2.505-.639.919-.132 2.12-.233 3.597-.228 1.552 0 2.765.121 3.718.263 1.187.193 1.948.422 2.268.574l-.086.177zm.522-.649l-.106.188c-.117-.147-.939-.462-2.445-.705-.99-.147-2.273-.29-3.886-.29a27.98 27.98 0 00-3.724.239 11.51 11.51 0 00-2.587.69l-.07-.147c.294-.122 1.09-.457 2.627-.7a28.432 28.432 0 013.754-.239c1.618 0 2.922.132 3.916.284 1.466.249 2.465.599 2.521.68z"
        fill="#FFE831"
      />
      <path
        d="M15.002 14.064l.005-.015c-.294-.147-1.025-.426-2.207-.588a25.25 25.25 0 00-3.698-.264h-.05a25.53 25.53 0 00-3.531.223c-1.476.208-2.227.518-2.445.624l.01.015.015-.01-.086-.142-.015.01.005.015c.279-.101 1.05-.44 2.5-.634.903-.132 2.09-.228 3.546-.228h.051c1.552 0 2.765.122 3.718.264 1.182.193 1.948.42 2.263.568l.005-.015-.015-.005-.087.182h.016l.005-.015-.005.015.015.005.086-.182.01-.016-.02-.005c-.32-.152-1.08-.385-2.273-.573a25.75 25.75 0 00-3.723-.258h-.05c-1.452 0-2.644.101-3.547.228-1.455.193-2.232.537-2.51.639l-.02.005.095.162.01.016.016-.006c.213-.106.959-.41 2.43-.623.892-.132 2.085-.224 3.525-.224h.051c1.547 0 2.744.127 3.693.264 1.177.162 1.907.441 2.196.583l.02.01.006-.015-.01-.01zm.522-.649l-.015-.01-.107.188.016.01.015-.01c-.127-.158-.944-.467-2.455-.716-.99-.147-2.278-.289-3.891-.289H9.04c-1.512 0-2.744.112-3.683.239-.938.147-1.74.35-2.592.69l.005.015.015-.005-.07-.147-.016.004.005.016c.295-.122 1.086-.457 2.623-.7a27.763 27.763 0 013.703-.239h.046c1.618 0 2.916.132 3.91.284 1.462.249 2.471.61 2.507.675l.03-.005-.015-.01.015.01.015-.01c-.07-.102-1.06-.436-2.536-.695a25.776 25.776 0 00-3.916-.284H9.04c-1.522 0-2.764.111-3.708.238-1.542.244-2.339.584-2.633.705l-.02.005.081.163.005.015.015-.005a11.65 11.65 0 012.582-.685 27.278 27.278 0 013.678-.238h.046c1.613 0 2.896.142 3.886.289 1.501.243 2.328.558 2.434.7l.016.02.121-.208.005-.01-.005-.01-.02.01z"
        fill="#1E1E1E"
      />
      <path d="M9.614 3.163h-.152v14.173h.152V3.163z" fill="#FFE831" />
      <path
        d="M9.614 17.336v-.02h-.137V3.178h.117v14.158h.02v-.02.02h.016V3.143h-.188V17.35h.188v-.015h-.016z"
        fill="#1E1E1E"
      />
      <path d="M8.899 3.163h-.152v14.173h.152V3.163z" fill="#FFE831" />
      <path
        d="M8.9 17.336v-.02h-.133V3.178h.117v14.158h.015v-.02.02h.02V3.143h-.187V17.35h.187v-.015H8.9z"
        fill="#1E1E1E"
      />
      <path d="M9.462 3.229H8.9V17.26h.563V3.229z" fill="#FFE831" />
      <path
        d="M9.462 17.265v-.02H8.92V3.249h.523v14.016h.02v-.02.02h.015V3.214h-.593V17.28h.593v-.015h-.015z"
        fill="#1E1E1E"
      />
      <path
        d="M3.238 6.298c2.181-.03 12.24 6.148 12.27 7.284l-.623.954c-.28-1.319-10.49-7.33-12.175-7.219l.528-1.02z"
        fill="#FFE831"
      />
      <path
        d="M3.238 6.313h.015c2.192-.01 12.225 6.164 12.236 7.264h.015l-.015-.01-.624.959.015.01.02-.005c-.294-1.329-10.303-7.224-12.144-7.234h-.05v.015l.014.01.528-1.024-.03-.015-.528 1.024-.015.03h.081c1.806-.004 11.845 5.92 12.109 7.21l.01.04.65-.99V13.589c-.047-1.162-10.045-7.295-12.272-7.3h-.015v.025z"
        fill="#1E1E1E"
      />
      <path
        d="M15.57 13.653c.253-.502-2.501-2.582-5.92-4.606C6.155 7.07 3.639 5.902 3.177 6.252l-.107.188.026-.03.284-.072c.791.016 3.054 1.056 6.23 2.877 1.39.811 5.873 3.688 5.858 4.494l-.02.117.122-.173zM2.644 7.282c.406-.28 3.379 1.05 6.488 2.922 3.1 1.882 6.062 4.007 5.799 4.403l-.087.137-.04.03-.005-.208c-.132-.436-2.237-2.11-5.722-4.22-3.4-2.03-6.235-3.252-6.519-2.902l.086-.162z"
        fill="#FFE831"
      />
      <path
        d="M15.585 13.658l.02-.08c-.01-.635-2.673-2.608-5.945-4.546C6.576 7.287 4.258 6.17 3.426 6.17l-.26.066-.106.193.03.02.026-.03.28-.061c.775.01 3.043 1.05 6.218 2.87 1.385.802 5.864 3.689 5.854 4.475v.005l-.02.112.036.01.101-.173-.03-.02-.066.101.005-.035v-.005c-.01-.842-4.48-3.693-5.87-4.505-3.18-1.82-5.437-2.86-6.239-2.876l-.29.071-.035.036.016.01.015.01.106-.188.234-.05c.811 0 3.14 1.11 6.219 2.855 3.267 1.928 5.935 3.932 5.925 4.515l-.015.066.025.015zM2.645 7.282l.01.015.157-.035c.756 0 3.48 1.253 6.31 2.957 2.968 1.796 5.814 3.84 5.804 4.327l-.016.05-.086.138-.005.005-.005-.173c-.142-.451-2.242-2.12-5.732-4.235C6.069 8.535 3.507 7.373 2.75 7.368l-.208.066.015.01.015.01.087-.172h-.016l-.015-.01-.086.172.03.02.183-.05c.73 0 3.302 1.161 6.31 2.957 3.486 2.1 5.596 3.79 5.717 4.205l.006.203v.036l.07-.05.092-.143.02-.07c-.01-.544-2.85-2.553-5.823-4.358-2.836-1.71-5.55-2.963-6.331-2.963l-.178.04.005.01z"
        fill="#1E1E1E"
      />
      <path
        d="M4.993 10.95c0 1.146.472 2.18 1.233 2.942.766.76 1.8 1.238 2.947 1.238a4.183 4.183 0 002.962-1.228 4.17 4.17 0 001.233-2.952V5.354H4.993v5.596z"
        fill="#fff"
      />
      <path
        d="M4.993 10.95h-.015c0 1.151.472 2.191 1.232 2.957a4.193 4.193 0 002.963 1.243 4.21 4.21 0 004.21-4.2V5.34H4.978v5.61h.03V5.375h8.34v5.58a4.153 4.153 0 01-1.228 2.942 4.164 4.164 0 01-2.952 1.222c-1.147 0-2.176-.476-2.937-1.232a4.117 4.117 0 01-1.228-2.932h-.01v-.005z"
        fill="#1E1E1E"
      />
      <path
        d="M5.166 10.95c0 1.1.456 2.1 1.181 2.825a4.021 4.021 0 002.836 1.177c1.106 0 2.11-.447 2.836-1.172a3.97 3.97 0 001.177-2.825V5.537h-8.03v5.413z"
        fill="#FF1507"
      />
      <path
        d="M5.145 10.95a4 4 0 001.187 2.835 4.04 4.04 0 002.851 1.182 4.04 4.04 0 002.851-1.177 3.987 3.987 0 001.182-2.835V5.522H5.15l-.005 5.428h.036l.005-5.393h7.995v5.398a3.96 3.96 0 01-1.172 2.81 3.97 3.97 0 01-2.826 1.167 4.002 4.002 0 01-2.82-1.172 3.957 3.957 0 01-1.182-2.81h-.036z"
        fill="#1E1E1E"
      />
      <path
        d="M6.804 10.706c0 .715.269 1.365.7 1.836.431.472 1.025.766 1.68.766.653 0 1.247-.289 1.678-.76a2.716 2.716 0 00.695-1.837v-3.52H6.81l-.005 3.515z"
        fill="#fff"
      />
      <path
        d="M6.789 10.706c0 .72.269 1.375.705 1.847a2.282 2.282 0 001.69.77c.659 0 1.257-.294 1.688-.765.432-.472.7-1.127.7-1.847v-3.54H6.79v3.535h.035v-3.5h4.718v3.505c0 .71-.264 1.355-.695 1.826a2.25 2.25 0 01-1.664.756c-.644 0-1.238-.289-1.664-.76a2.69 2.69 0 01-.695-1.827H6.79z"
        fill="#1E1E1E"
      />
      <path d="M8.656 10.346l.157.406.365.167.37-.167.153-.406v-.771H8.656v.771z" fill="#0A51A1" />
      <path
        d="M9.468 9.742l-.104.104.104.104.104-.104-.104-.104zM9.173 10.026l-.101.102.101.106.107-.106-.107-.102zM9.467 10.32l-.106.102.106.106.107-.106-.107-.101zM8.894 10.32l-.107.107.107.101.106-.1-.106-.107zM8.894 9.742l-.107.107.107.101L9 9.85l-.106-.107z"
        fill="#fff"
      />
      <path d="M7.19 10.346l.152.406.37.167.37-.167.152-.406v-.771H7.19v.771z" fill="#0A51A1" />
      <path
        d="M7.997 9.741l-.104.104.104.105.104-.104-.104-.105zM7.702 10.026l-.102.102.102.106.106-.106-.106-.102zM7.996 10.32l-.101.102.101.106.107-.106-.107-.101zM7.423 10.32l-.101.107.101.101.107-.1-.107-.107zM7.423 9.742l-.101.107.101.101.107-.101-.107-.107z"
        fill="#fff"
      />
      <path d="M10.127 10.346l.152.406.37.167.37-.167.158-.406v-.771h-1.05v.771z" fill="#0A51A1" />
      <path
        d="M10.935 9.743l-.104.104.104.104.104-.104-.104-.104zM10.644 10.026l-.106.102.106.106.107-.106-.107-.102zM10.938 10.32l-.106.102.106.106.102-.106-.102-.101zM10.365 10.32l-.107.107.107.101.107-.1-.107-.107zM10.365 9.742l-.107.107.107.101.101-.101-.1-.107z"
        fill="#fff"
      />
      <path d="M8.656 12.116v.005l.157.401.365.168.37-.168.153-.4v-.777H8.656v.771z" fill="#0A51A1" />
      <path
        d="M9.467 11.513l-.103.104.104.104.104-.104-.105-.104zM9.173 11.797l-.101.101.101.107.107-.107-.107-.101zM9.467 12.09l-.106.102.106.107.107-.107-.107-.101zM8.894 12.09l-.107.107.107.102.106-.102-.106-.106zM8.894 11.513l-.107.106.107.102.106-.102-.106-.106z"
        fill="#fff"
      />
      <path d="M8.656 8.575l.157.406.365.168.37-.168.153-.4v-.782H8.656v.776z" fill="#0A51A1" />
      <path
        d="M9.468 7.972l-.104.104.104.104.104-.104-.104-.104zM9.173 8.256l-.101.101.101.107.107-.107-.107-.101zM9.467 8.55l-.106.101.106.107.107-.106-.107-.102zM8.894 8.55l-.107.101.107.107L9 8.652l-.106-.102zM8.894 7.972l-.107.106.107.102L9 8.078l-.106-.106z"
        fill="#fff"
      />
      <path d="M8.6 6.947h1.162l-.056-.451H8.65l-.051.451z" fill="#FFE831" />
      <path
        d="M8.6 6.947v.015h1.182l-.056-.466-.005-.016H8.635l-.05.462-.005.02h.02v-.015h.02l.046-.431H9.69l.05.41H8.6v.021h.02-.02z"
        fill="#1E1E1E"
      />
      <path d="M9.178 6.531l.29.416h-.574l.284-.416z" fill="#FFE831" />
      <path d="M9.163 6.546l.274.38h-.513l.27-.38-.031-.025-.284.41-.02.031h.644l-.315-.441-.025.025z" fill="#1E1E1E" />
      <path d="M9.178 6.633s.167.08.167.314h-.324c0-.233.157-.314.157-.314z" fill="#FFE831" />
      <path
        d="M9.178 6.633l-.01.015s.157.076.157.3h.015v-.016h-.324v.015h.015c0-.223.152-.294.152-.3l-.005-.014-.01.015.01-.015-.01-.016S9 6.704 9 6.947v.015h.361v-.015c0-.243-.173-.33-.178-.33l-.005-.005-.01.005.01.016z"
        fill="#1E1E1E"
      />
      <path d="M8.58 6.947h1.207l.04.051-.04.056H8.58l-.046-.056.046-.05z" fill="#FFE831" />
      <path
        d="M8.58 6.947v.015h1.197l.03.036-.03.035H8.59l-.036-.035.036-.04-.01-.011v.015-.015l-.016-.015-.045.056-.01.01.055.066.005.005H9.792l.05-.061.01-.01-.055-.066-.005-.005H8.57l-.006.005.016.015z"
        fill="#1E1E1E"
      />
      <path d="M11.8 6.947h1.157l-.05-.451h-1.06l-.046.451z" fill="#FFE831" />
      <path
        d="M11.8 6.947v.015h1.177l-.055-.466V6.48H11.83l-.05.462v.02h.02v-.015h.015l.045-.431h1.03l.046.41H11.8v.021h.015-.015z"
        fill="#1E1E1E"
      />
      <path d="M12.374 6.531l.294.416h-.578l.284-.416z" fill="#FFE831" />
      <path
        d="M12.359 6.546l.274.38h-.508l.264-.38-.03-.025-.284.41-.016.031h.645l-.315-.441-.03.025z"
        fill="#1E1E1E"
      />
      <path d="M12.374 6.633s.167.08.167.314h-.33c.006-.233.163-.314.163-.314z" fill="#FFE831" />
      <path
        d="M12.374 6.633l-.005.015s.157.076.157.3h.015v-.016h-.33v.015h.016c0-.223.152-.294.152-.3l-.005-.014-.005.015.005-.015-.01-.016s-.168.087-.168.33v.015h.365v-.015c0-.243-.172-.33-.177-.33l-.005-.005-.01.005.005.016z"
        fill="#1E1E1E"
      />
      <path d="M11.775 6.947h1.208l.045.051-.045.056h-1.208l-.045-.056.045-.05z" fill="#FFE831" />
      <path
        d="M11.775 6.947v.015h1.197l.031.036-.03.035h-1.188l-.035-.035.04-.04-.015-.011v.015-.015l-.01-.015-.045.056-.016.01.061.066.005.005h1.218l.05-.061.01-.01-.055-.066-.005-.005H11.77l-.005.005.01.015z"
        fill="#1E1E1E"
      />
      <path d="M11.8 10.305h1.157l-.05-.451h-1.06l-.046.451z" fill="#FFE831" />
      <path
        d="M11.8 10.305v.02h1.177l-.055-.471v-.015H11.83l-.05.466v.02h.02v-.02h.015l.045-.43h1.03l.046.415H11.8v.015h.015-.015z"
        fill="#1E1E1E"
      />
      <path d="M12.374 9.895l.294.41h-.578l.284-.41z" fill="#FFE831" />
      <path d="M12.359 9.905l.274.385h-.508l.264-.385-.03-.02-.284.41-.016.03h.64l-.31-.44-.03.02z" fill="#1E1E1E" />
      <path d="M12.374 9.99s.167.082.167.315h-.33c.006-.233.163-.314.163-.314z" fill="#FFE831" />
      <path
        d="M12.374 9.99l-.005.016s.157.071.157.3h.015v-.016h-.33v.015h.016c0-.223.152-.294.152-.299l-.005-.015-.005.015.005-.015-.01-.015s-.168.086-.168.33v.02h.365v-.02c0-.244-.172-.33-.177-.33l-.005-.006-.01.006.005.015z"
        fill="#1E1E1E"
      />
      <path d="M11.806 9.656h1.146l.046.05-.046.051h-1.146l-.04-.05.04-.051z" fill="#FFE831" />
      <path
        d="M11.806 9.656v.02h1.136l.03.03-.03.031h-1.126l-.03-.03.035-.04-.015-.01v.02-.02l-.01-.011-.046.05-.01.01.056.062.005.005h1.161l.046-.056.015-.01-.056-.061-.01-.005h-1.156l-.005.005.01.01z"
        fill="#1E1E1E"
      />
      <path d="M11.775 10.305h1.208l.045.051-.045.056h-1.208l-.045-.056.045-.05z" fill="#FFE831" />
      <path
        d="M11.775 10.305v.02h1.197l.031.031-.03.036h-1.188l-.03-.036.036-.04-.016-.01v.02-.02l-.01-.01-.045.05-.016.01.061.066.005.005h1.218l.05-.056.016-.015-.061-.06-.005-.006H11.77l-.005.005.01.01z"
        fill="#1E1E1E"
      />
      <path d="M11.831 9.758h1.096l.04.045-.04.05H11.83l-.04-.05.04-.045z" fill="#FFE831" />
      <path
        d="M11.831 9.757v.016h1.086l.03.03-.03.03H11.84l-.03-.03.03-.036-.01-.01v.016-.016l-.015-.01-.04.046-.006.01.046.06.005.006h1.116l.046-.05.005-.016-.046-.056-.005-.01h-1.116l-.005.01.015.01z"
        fill="#1E1E1E"
      />
      <path d="M12.516 9.19l.02.471-.304-.005.025-.467h.259z" fill="#FFE831" />
      <path
        d="M12.5 9.19l.021.45-.269-.004.02-.432h.244V9.17h-.279l-.02.487-.005.015.324.005h.02l-.02-.487h-.035z"
        fill="#1E1E1E"
      />
      <path
        d="M12.196 9.012h.087v.06h.06v-.06h.087v.06h.06v-.06h.087v.142l-.036.035h-.304l-.04-.035v-.142z"
        fill="#FFE831"
      />
      <path
        d="M12.196 9.012v.02h.066v.06h.097v-.065h.05v.066H12.511v-.066h.05v.117l-.025.03h-.294l-.025-.03v-.132h-.02v.02-.02h-.016v.147l.04.046.006.005h.319l.046-.046.005-.005V8.992h-.122v.065h-.03v-.065H12.324v.065h-.026v-.06H12.18v.015h.015zM12.414 9.382h-.06v.112h.06v-.112z"
        fill="#1E1E1E"
      />
      <path
        d="M12.567 9.235h.086v.06h.06v-.06h.087v.06h.06v-.06h.092v.142l-.04.035h-.305l-.04-.035v-.142z"
        fill="#FFE831"
      />
      <path
        d="M12.567 9.235v.015h.07v.066h.097V9.25h.046v.066h.101V9.25h.051v.117l-.025.025h-.3l-.02-.025v-.132h-.02v.015-.015h-.015v.147l.045.04.315.006.05-.04.005-.006V9.215h-.121v.066h-.03v-.066H12.698v.066h-.026v-.066h-.121v.02h.015z"
        fill="#1E1E1E"
      />
      <path d="M12.896 9.413l.005.248h-.289l.01-.248h.274z" fill="#FFE831" />
      <path
        d="M12.881 9.413l.005.228h-.253l.005-.213h.258v-.036H12.602l-.005.27v.014h.325l-.005-.263h-.036z"
        fill="#1E1E1E"
      />
      <path
        d="M11.821 9.235h.081v.06h.066v-.06h.081v.06h.066v-.06h.086v.142l-.035.035h-.31l-.035-.035v-.142z"
        fill="#FFE831"
      />
      <path
        d="M11.821 9.235v.015h.066v.066h.096V9.25h.051v.066h.096V9.25h.056v.117l-.03.025h-.3l-.02-.025v-.132h-.015v.015-.015h-.02v.147l.045.04.32.006.046-.04.01-.006V9.215h-.127v.066h-.025v-.066h-.122v.066h-.026v-.066H11.801v.02h.02z"
        fill="#1E1E1E"
      />
      <path d="M12.146 9.413l.01.248h-.29l.006-.248h.274z" fill="#FFE831" />
      <path
        d="M12.13 9.413l.006.228h-.254l.01-.213h.254v-.036H11.857l-.01.27v.014h.324l-.005-.263h-.036zM12.79 9.519h-.061v.066h.06v-.066z"
        fill="#1E1E1E"
      />
      <path d="M12.04 9.519h-.062v.066h.061v-.066z" fill="#1E1E1E" />
      <path d="M10.817 12.958l.847.797.269-.36-.771-.73-.345.293z" fill="#FFE831" />
      <path
        d="M10.817 12.958l-.01.01.842.797.015.015.279-.375.015-.016-.786-.74-.01-.01-.356.304-.015.015.015.01.01-.01.016.01.33-.279.745.706-.248.334-.827-.786-.015.015.015.01-.015-.01z"
        fill="#1E1E1E"
      />
      <path d="M11.522 13.055l-.071.497-.421-.396.492-.101z" fill="#FFE831" />
      <path
        d="M11.501 13.05l-.066.466-.37-.35.462-.096-.01-.036-.492.102-.03.01.446.421.02.02.076-.532-.036-.005z"
        fill="#1E1E1E"
      />
      <path d="M11.456 13.126l-.097.34-.238-.224.335-.116z" fill="#FFE831" />
      <path
        d="M11.456 13.126l-.02-.005-.087.314-.193-.188.305-.106-.005-.015-.02-.005.02.005-.01-.02-.33.116-.025.015.253.244.026.02.101-.37.01-.036-.035.01.01.02z"
        fill="#1E1E1E"
      />
      <path d="M11.273 12.492l.827.786v.066l-.066.005-.832-.786.005-.066.066-.005z" fill="#FFE831" />
      <path
        d="M11.273 12.492l-.01.01.822.786v.04l-.046.006-.822-.776.005-.046.051-.005v-.015l-.01.01.01-.01-.005-.02-.066.005-.01.005-.01.08v.006l.842.796.005.005.071-.005.02-.005V13.273l-.837-.796-.005-.006h-.01l.005.02z"
        fill="#1E1E1E"
      />
      <path d="M10.801 12.943l.873.827v.071l-.071.005-.873-.832v-.066l.071-.005z" fill="#FFE831" />
      <path
        d="M10.801 12.943l-.01.01.868.827v.046l-.046.005-.867-.822.005-.046.055-.005-.005-.015-.01.01.01-.01v-.02l-.07.005-.016.005-.005.081v.01l.883.837.005.005.08-.01h.016v-.091l-.883-.837v-.005h-.01v.02z"
        fill="#1E1E1E"
      />
      <path d="M11.217 12.583l.797.75v.067l-.066.005-.797-.756.005-.06.061-.006z" fill="#FFE831" />
      <path
        d="M11.217 12.583l-.01.01.792.746-.006.045-.04.006-.781-.746v-.04l.05-.006-.005-.015-.01.01.01-.01v-.02l-.066.005-.015.005v.086l.797.761.01.005.07-.01h.016l.005-.081v-.005l-.801-.761-.006-.005h-.01v.02z"
        fill="#1E1E1E"
      />
      <path d="M12.11 12.639l-.31.355-.218-.208.335-.325.193.178z" fill="#FFE831" />
      <path
        d="M12.095 12.629l-.3.334-.187-.177.31-.3.182.168.02-.025-.193-.183-.01-.01-.35.335-.01.015.228.218.016.01.32-.365-.026-.02z"
        fill="#1E1E1E"
      />
      <path
        d="M11.998 12.289l.061.06-.045.041.05.046.041-.046.06.061-.04.046.046.04.04-.045.061.06-.096.102h-.05l-.224-.208v-.056L12 12.29z"
        fill="#FFE831"
      />
      <path
        d="M11.998 12.289l-.015.015.051.046-.03.03-.01.01.055.061.016.01.04-.045.035.035-.03.036-.01.01.056.056.015.01.04-.046.041.036-.081.086h-.046l-.203-.198-.005-.04.097-.097-.015-.015-.016.015.015-.015-.015-.01-.096.101-.005.005.005.061v.01l.223.213.01.005h.066l.102-.111.01-.01-.071-.077-.015-.01-.041.046-.02-.015.03-.036.01-.01-.07-.076-.016-.01-.04.046-.026-.02.03-.026.016-.015-.071-.071-.015-.01-.016.01.015.01zM11.856 12.67l.04.045-.07.076-.046-.04.076-.082z"
        fill="#1E1E1E"
      />
      <path
        d="M12.115 12.71l.061.055-.04.046.04.04.046-.045.06.061-.04.046.046.045.04-.045.066.055-.101.102-.051.005-.223-.213-.005-.05.101-.102z"
        fill="#FFE831"
      />
      <path
        d="M12.115 12.71l-.015.01.05.045-.03.036-.01.01.056.056.01.01.046-.046.035.036-.03.035-.01.01.056.056.015.01.04-.045.04.035-.085.092-.036-.005-.208-.198-.005-.04.091-.097-.01-.01-.015.01.015-.01-.015-.016-.096.102-.005.01.005.06v.006l.228.213.005.005h.056l.111-.111.01-.01-.07-.072-.016-.01-.04.046-.02-.02.03-.036.01-.015-.076-.071-.015-.01-.04.045-.021-.015.03-.035.015-.01-.076-.072-.01-.01-.015.01.015.016z"
        fill="#1E1E1E"
      />
      <path d="M12.227 13.065l-.163.177-.208-.198.173-.172.198.193z" fill="#FFE831" />
      <path
        d="M12.217 13.05l-.153.172-.182-.178.147-.147.188.178.025-.025-.203-.188-.01-.015-.188.187-.01.01.223.214.01.01.178-.193-.025-.025z"
        fill="#1E1E1E"
      />
      <path
        d="M11.572 12.192l.061.061-.04.046.04.04.046-.045.06.06-.04.046.04.04.046-.045.061.061-.096.102h-.05l-.224-.208-.005-.056.101-.102z"
        fill="#FFE831"
      />
      <path
        d="M11.572 12.192l-.015.016.051.045-.03.036-.01.01.055.05.01.01.046-.04.04.036-.035.035-.01.01.056.056.01.01.046-.045.035.035-.081.086h-.036l-.208-.203-.005-.035.092-.096-.01-.016-.016.016.015-.016-.015-.01-.096.102-.005.005.005.06v.006l.223.218.005.005h.066l.107-.112.01-.01-.076-.076-.01-.01-.046.046-.02-.016.035-.035.01-.01-.076-.076-.01-.01-.046.045-.02-.02.03-.03.01-.016-.07-.066-.01-.015-.016.015.015.01z"
        fill="#1E1E1E"
      />
      <path d="M11.684 12.548l-.157.182-.213-.198.172-.172.198.188z" fill="#FFE831" />
      <path
        d="M11.674 12.537l-.152.168-.188-.173.152-.147.188.178.025-.03-.203-.188-.01-.01-.188.182-.01.015.223.213.016.01.172-.197-.025-.02zM12.034 13.024l.046.046-.046.046-.046-.046.046-.046z"
        fill="#1E1E1E"
      />
      <path d="M11.491 12.512l.046.04-.046.046-.045-.04.045-.046z" fill="#1E1E1E" />
      <path d="M7.59 12.958l-.842.797-.274-.36.771-.73.345.293z" fill="#FFE831" />
      <path
        d="M7.59 12.958l-.01-.015-.832.786-.248-.334.745-.706.335.28.01-.01-.01-.016.01.015.01-.015-.344-.294-.01-.01-.782.74-.015.01.284.376.01.015.857-.812.016-.01-.016-.015-.01.015z"
        fill="#1E1E1E"
      />
      <path d="M6.885 13.055l.071.497.416-.396-.487-.101z" fill="#FFE831" />
      <path
        d="M6.87 13.055l.07.502.006.03.441-.416.02-.025-.517-.112-.005.036.452.096-.366.35-.066-.466-.035.005z"
        fill="#1E1E1E"
      />
      <path d="M6.951 13.126l.097.34.238-.224-.335-.116z" fill="#FFE831" />
      <path
        d="M6.951 13.126l-.015.005.096.34.01.03.254-.243.02-.02-.36-.133-.03-.01.01.036.015-.005-.005.015.304.106-.192.188-.087-.314-.02.005-.005.015.005-.015z"
        fill="#1E1E1E"
      />
      <path d="M7.139 12.492l-.832.786v.066l.066.005.832-.786-.005-.066-.061-.005z" fill="#FFE831" />
      <path
        d="M7.139 12.492l-.01-.016-.837.792-.005.005.005.07v.016l.076.01h.01l.837-.791.005-.01v-.086l-.081-.01h-.01v.005l.01.015-.005.015.05.005v.046l-.821.776-.036-.005-.005-.04.827-.787-.01-.01-.005.015.005-.015z"
        fill="#1E1E1E"
      />
      <path d="M7.606 12.943l-.878.827.005.071.066.005.878-.832-.005-.066-.066-.005z" fill="#FFE831" />
      <path
        d="M7.605 12.943l-.015-.015-.872.832-.005.005v.091l.086.01h.01l.878-.837.01-.005-.005-.08-.005-.011-.082-.01H7.6l-.01.005.015.015-.005.015.056.005.005.046-.867.822-.046-.005v-.046l.868-.827-.01-.01-.006.015.005-.015z"
        fill="#1E1E1E"
      />
      <path d="M7.184 12.583l-.79.75v.067l.065.005.796-.756-.005-.06-.066-.006z" fill="#FFE831" />
      <path
        d="M7.184 12.583l-.01-.015-.796.75-.005.01.005.072v.015l.081.01h.01l.797-.76.005-.006v-.076l-.005-.01-.076-.01h-.01l-.006.005.01.015v.015l.051.005v.04l-.781.747-.04-.006-.006-.045.792-.746-.016-.01v.015-.015z"
        fill="#1E1E1E"
      />
      <path d="M6.297 12.639l.31.355.217-.208-.335-.325-.192.178z" fill="#FFE831" />
      <path
        d="M6.281 12.649l.31.355.015.01.228-.218.016-.01-.345-.34-.016-.01-.202.193.02.025.182-.168.31.3-.193.182-.294-.34-.03.02z"
        fill="#1E1E1E"
      />
      <path
        d="M6.408 12.289l-.06.06.04.041-.04.046-.046-.046-.06.061.04.046-.04.04-.047-.045-.06.06.096.102h.05l.224-.208v-.056l-.097-.101z"
        fill="#FFE831"
      />
      <path
        d="M6.408 12.289l-.01-.01-.06.06-.01.01.035.041-.015.02-.03-.03-.016-.015-.076.076-.01.01.045.046-.02.015-.035-.03-.01-.016-.077.076-.01.01.107.117.01.005h.066l.223-.213.005-.005V12.385l-.096-.106-.016-.016-.01.016.01.01-.01.015.087.096v.041l-.208.198h-.041l-.081-.086.04-.036.03.03.016.016.05-.051.016-.015-.046-.046.04-.035.031.03.016.015.055-.056.01-.015-.04-.04.05-.046-.015-.015-.01.015.01-.015zM6.55 12.67l-.04.045.07.076.046-.04-.076-.082z"
        fill="#1E1E1E"
      />
      <path
        d="M6.292 12.71l-.061.055.046.046-.046.04-.046-.045-.06.061.04.046-.046.045-.04-.045-.061.055.096.102.05.005.22-.213.004-.05-.096-.102z"
        fill="#FFE831"
      />
      <path
        d="M6.292 12.71l-.015-.016-.056.061-.016.01.046.046-.02.015-.03-.03-.016-.015-.076.07-.01.016.04.046-.02.02-.03-.036-.01-.01-.076.071-.01.01.106.117.005.005h.066l.228-.213.005-.005.005-.06v-.006l-.101-.111-.015-.01-.015.01.015.015-.015.01.096.096-.005.04-.213.199h-.036l-.08-.087.04-.035.03.035.01.01.061-.05.01-.015-.04-.046.035-.036.03.03.016.016.056-.05.01-.016-.04-.046.045-.045-.01-.01-.015.01.015-.01z"
        fill="#1E1E1E"
      />
      <path d="M6.175 13.065l.162.177.213-.198-.172-.172-.203.193z" fill="#FFE831" />
      <path
        d="M6.165 13.075l.162.183.01.01.223-.208.016-.016-.188-.182-.01-.015-.213.203.025.025.188-.178.147.147-.188.178-.147-.172-.025.025z"
        fill="#1E1E1E"
      />
      <path
        d="M6.834 12.192l-.06.061.045.046-.05.04-.041-.045-.061.06.04.046-.045.04-.04-.045-.067.061.102.102h.05l.224-.208v-.056l-.097-.102z"
        fill="#FFE831"
      />
      <path
        d="M6.834 12.192l-.01-.01-.06.056-.016.01.046.046-.02.02-.03-.03-.016-.016-.071.071-.015.01.04.046-.02.02-.03-.03-.01-.015-.077.07-.01.016.107.112.005.01h.066l.228-.218.005-.005.005-.061v-.005l-.101-.107-.016-.015-.01.015.01.01-.01.01.092.102-.005.035-.208.203h-.041l-.081-.086.04-.035.03.03.011.015.056-.055.015-.01-.04-.046.035-.036.03.03.01.01.061-.05.01-.01-.04-.046.05-.045-.015-.016-.01.01.01-.01z"
        fill="#1E1E1E"
      />
      <path d="M6.723 12.548l.162.182.208-.198-.172-.172-.198.188z" fill="#FFE831" />
      <path
        d="M6.708 12.558l.162.182.01.015.223-.208.015-.015-.187-.187-.01-.01-.213.197.025.03.188-.177.147.147-.183.173-.152-.168-.025.02zM6.373 13.024l-.046.046.046.046.045-.046-.045-.046z"
        fill="#1E1E1E"
      />
      <path d="M6.916 12.512l-.046.04.046.046.045-.04-.045-.046z" fill="#1E1E1E" />
      <path d="M5.404 6.947H6.56l-.05-.451H5.45l-.046.451z" fill="#FFE831" />
      <path
        d="M5.404 6.947v.015H6.58l-.055-.466V6.48H5.434l-.05.462v.02h.02v-.015h.015l.046-.431h1.03l.045.41H5.404v.021h.015-.015z"
        fill="#1E1E1E"
      />
      <path d="M5.977 6.531l.294.416h-.578l.284-.416z" fill="#FFE831" />
      <path d="M5.962 6.546l.274.38h-.507l.263-.38-.03-.025-.284.41-.015.031h.644l-.315-.441-.03.025z" fill="#1E1E1E" />
      <path d="M5.982 6.633s.168.08.168.314h-.33c0-.233.162-.314.162-.314z" fill="#FFE831" />
      <path
        d="M5.982 6.633l-.01.015s.157.076.157.3h.02v-.016h-.33v.015h.016c0-.223.152-.294.152-.3l-.005-.014-.01.015.01-.015-.01-.016s-.172.087-.172.33v.015h.36v-.015c0-.243-.173-.33-.173-.33l-.01-.005-.01.005.015.016z"
        fill="#1E1E1E"
      />
      <path d="M5.379 6.947h1.207l.046.051-.046.056H5.379l-.046-.056.046-.05z" fill="#FFE831" />
      <path
        d="M5.379 6.947v.015h1.197l.03.036-.03.035H5.389l-.03-.035.035-.04-.015-.011v.015-.015l-.01-.015-.046.056-.015.01.06.066.006.005h1.222l.05-.061.011-.01-.056-.066-.005-.005H5.374l-.006.005.01.015z"
        fill="#1E1E1E"
      />
      <path d="M5.404 10.305H6.56l-.05-.451H5.45l-.046.451z" fill="#FFE831" />
      <path
        d="M5.404 10.305v.015H6.58l-.055-.466v-.015H5.434l-.05.466v.015h.02v-.015h.015l.046-.43h1.03l.045.415H5.404v.015h.015-.015z"
        fill="#1E1E1E"
      />
      <path d="M5.977 9.895l.294.41h-.578l.284-.41z" fill="#FFE831" />
      <path d="M5.962 9.905l.274.385h-.507l.263-.385-.03-.02-.284.41-.015.025H6.3l-.309-.436-.03.02z" fill="#1E1E1E" />
      <path d="M5.982 9.99s.168.082.168.315h-.33c0-.233.162-.314.162-.314z" fill="#FFE831" />
      <path
        d="M5.982 9.99l-.01.016s.157.071.157.3h.02v-.016h-.33v.015h.016c0-.223.152-.294.152-.299l-.005-.015-.01.015.01-.015-.01-.015s-.172.086-.172.33v.014h.36v-.015c0-.243-.173-.33-.178-.33l-.005-.005-.01.006.015.015z"
        fill="#1E1E1E"
      />
      <path d="M5.409 9.656h1.146l.046.05-.046.051H5.41l-.04-.05.04-.051z" fill="#FFE831" />
      <path
        d="M5.409 9.656v.02h1.136l.03.03-.03.031H5.42l-.03-.03.035-.04-.015-.01v.02-.02l-.01-.011-.046.05-.01.01.056.062.005.005h1.161l.046-.056.015-.01-.055-.061-.01-.005H5.403l-.005.005.01.01z"
        fill="#1E1E1E"
      />
      <path d="M5.379 10.305h1.207l.046.051-.046.056H5.379l-.046-.056.046-.05z" fill="#FFE831" />
      <path
        d="M5.379 10.305v.015h1.197l.03.036-.03.036H5.389l-.03-.036.035-.04-.015-.01v.014-.015l-.01-.01-.046.05-.015.011.06.066.006.005h1.222l.05-.056.011-.015-.056-.06-.005-.006H5.374l-.006.005.01.01z"
        fill="#1E1E1E"
      />
      <path d="M5.434 9.758H6.53l.04.045-.04.05H5.434l-.04-.05.04-.045z" fill="#FFE831" />
      <path
        d="M5.434 9.757v.016H6.52l.03.03-.03.03H5.445l-.026-.03.03-.036-.015-.01v.016-.016l-.01-.01-.045.046-.01.01.055.06v.006H6.54l.046-.05.01-.016-.05-.056-.006-.01H5.424v.01l.01.01z"
        fill="#1E1E1E"
      />
      <path d="M6.124 9.19l.016.471-.305-.005.026-.467h.263z" fill="#FFE831" />
      <path
        d="M6.104 9.19l.02.45-.268-.004.02-.432h.248V9.17H5.841l-.021.487-.005.015.325.005h.02l-.02-.487h-.036z"
        fill="#1E1E1E"
      />
      <path
        d="M5.8 9.012h.086v.06h.06v-.06h.087v.06h.06v-.06h.087v.142l-.035.035H5.84l-.04-.035v-.142z"
        fill="#FFE831"
      />
      <path
        d="M5.8 9.012v.02h.066v.06h.096v-.065h.056v.066h.096v-.066h.05v.117l-.024.03h-.295l-.025-.03v-.132H5.8v.02-.02h-.016v.147l.046.046.005.005h.315l.045-.046.005-.005V8.992H6.08v.065h-.03v-.065H5.931v.065h-.03v-.06h-.118v.015H5.8zM6.023 9.382h-.061v.112h.06v-.112z"
        fill="#1E1E1E"
      />
      <path
        d="M6.17 9.235h.086v.06h.061v-.06h.086v.06h.066v-.06h.086v.142l-.04.035H6.21l-.041-.035v-.142z"
        fill="#FFE831"
      />
      <path
        d="M6.17 9.235v.015h.071v.066h.096V9.25h.051v.066h.097V9.25h.05v.117l-.025.025h-.3l-.02-.025v-.132h-.02v.015-.015h-.015v.147l.045.04.315.006h.005l.046-.04.005-.006V9.215h-.122v.066h-.025v-.066h-.122v.066h-.025v-.066h-.122v.02h.015z"
        fill="#1E1E1E"
      />
      <path d="M6.5 9.413l.01.248h-.294l.01-.248H6.5z" fill="#FFE831" />
      <path
        d="M6.48 9.413l.01.228h-.254l.005-.213H6.5v-.036H6.206l-.006.27v.014h.325l-.01-.263h-.036z"
        fill="#1E1E1E"
      />
      <path
        d="M5.424 9.235h.087v.06h.06v-.06h.087v.06h.06v-.06h.087v.142l-.036.035h-.31l-.035-.035v-.142z"
        fill="#FFE831"
      />
      <path
        d="M5.424 9.235v.015h.066v.066h.096V9.25h.051v.066h.101V9.25h.051v.117l-.03.025h-.29l-.025-.025v-.132h-.02v.015-.015H5.41v.147l.04.04.006.006h.319l.04-.04.01-.006V9.215h-.121v.066h-.03v-.066H5.55v.066h-.025v-.066H5.409v.02h.015z"
        fill="#1E1E1E"
      />
      <path d="M5.749 9.413l.01.248H5.47l.005-.248h.274z" fill="#FFE831" />
      <path
        d="M5.734 9.413l.01.228h-.259l.01-.213h.254v-.036H5.46l-.01.27v.014h.33l-.01-.263h-.036zM6.393 9.519h-.06v.066h.06v-.066z"
        fill="#1E1E1E"
      />
      <path d="M5.642 9.519h-.06v.066h.06v-.066z" fill="#1E1E1E" />
    </svg>
  );
};
