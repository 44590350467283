import React, { ChangeEventHandler } from 'react';
import styles from './RadioInput.module.scss';

type RadioInputProps = {
  id: string;
  label?: string;
  value: string | number | undefined;
  onChange: ChangeEventHandler<HTMLInputElement>;
  isChecked?: boolean;
  name: string;
  children?: JSX.Element | Array<JSX.Element>;
};

export const RadioInput = ({ id, label, value, onChange, isChecked, name, children }: RadioInputProps): JSX.Element => {
  return (
    <div className={styles.RadioInput}>
      <input id={id} type="radio" onChange={onChange} checked={isChecked} name={name} value={value} />
      <label htmlFor={id}>{label || children}</label>
    </div>
  );
};
