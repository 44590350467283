import { Message } from '../types/Message.type';
import * as types from './chat-room.action-types';
import { ChatRoomStoreTypes } from './chat-room.store-types';
import { ChatStatus } from '../types/ChatStatus.type';
import { UserStatus } from '../types/UserStatus.type';

// add a new message to chat room
export const setNewMessage = (message: Message): ChatRoomStoreTypes => ({
  type: types.SET_NEW_MESSAGE,
  message
});

// set patientCode
export const setPatientCode = (patientCode: string): ChatRoomStoreTypes => ({
  type: types.SET_PATIENT_CODE,
  patientCode
});
// set patientName
export const setPatientName = (patientName: string): ChatRoomStoreTypes => ({
  type: types.SET_PATIENT_NAME,
  patientName
});

// set operationID
export const setOperationID = (operationID: string): ChatRoomStoreTypes => ({
  type: types.SET_OPERATION_ID,
  operationID
});

// set status to chat room
export const setChatStatus = (status: ChatStatus): ChatRoomStoreTypes => ({
  type: types.SET_CHAT_STATUS,
  status
});

// set status to user
export const setUserStatus = (status: UserStatus): ChatRoomStoreTypes => ({
  type: types.SET_USER_STATUS,
  status
});

// set status to user
export const setChatImage = (imageUrl: string): ChatRoomStoreTypes => ({
  type: types.SET_CHAT_IMAGE,
  imageUrl
});

// update all messages from chat room
export const updateMessages = (messages: Message[]): ChatRoomStoreTypes => ({
  type: types.UPDATE_MESSAGES,
  messages
});
