import React from 'react';

export const SpanishFlag = (): JSX.Element => {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.296 7.596C1.622 2.239 7.044-1.031 12.4.296c5.361 1.326 8.627 6.743 7.3 12.105-1.326 5.356-6.743 8.627-12.105 7.3C2.24 18.375-1.03 12.958.296 7.596z"
        fill="#FF1507"
      />
      <path d="M.584 6.639h18.829A9.988 9.988 0 0012.4.295C7.368-.95 2.285 1.855.584 6.64z" fill="#FF1507" />
      <path
        d="M19.782 12.056c.006-.02.01-.04.016-.065.005-.036.015-.076.02-.112a.3.3 0 00.01-.066c.005-.04.015-.076.02-.116.005-.02.005-.04.01-.056.01-.045.016-.096.026-.142 0-.01.005-.02.005-.03.01-.056.015-.116.025-.172 0-.015.005-.03.005-.046l.015-.126c0-.02.005-.04.005-.061.006-.04.01-.076.01-.111 0-.02.006-.04.006-.061.005-.04.005-.076.01-.117 0-.02.005-.04.005-.06.005-.046.005-.086.01-.132 0-.015 0-.025.005-.04.005-.056.005-.117.01-.173v-.03c0-.046.005-.096.005-.142v-.056-.116-.06-.117-.056c0-.04 0-.086-.005-.126V9.62a7.788 7.788 0 00-.02-.344c0-.015 0-.03-.005-.046-.005-.04-.005-.08-.01-.126 0-.02-.005-.036-.005-.056-.005-.04-.01-.076-.01-.116 0-.02-.006-.036-.006-.056a8.973 8.973 0 01-.015-.127c0-.015-.005-.03-.005-.045-.015-.112-.03-.228-.05-.34 0-.01-.006-.02-.006-.03a1.26 1.26 0 00-.025-.137c-.005-.015-.005-.03-.01-.045-.005-.04-.015-.081-.025-.122-.005-.015-.005-.03-.01-.05-.01-.04-.015-.081-.026-.127-.005-.015-.005-.025-.01-.04-.01-.051-.025-.102-.035-.152l-.005-.01c-.015-.051-.026-.107-.04-.157a10.022 10.022 0 00-.264-.866H.584a9.96 9.96 0 00-.46 4.926c0 .005 0 .01.004.015.005.046.016.096.026.142 0 .01.005.02.005.03.01.046.015.086.025.132 0 .015.005.025.01.04.01.041.015.082.026.122.005.015.005.03.01.046.01.04.015.076.025.116l.015.056c.01.035.015.07.026.111.005.025.015.056.02.081l.03.122c.01.04.02.08.036.121l.015.05c.01.041.02.077.035.117l.015.056c.01.035.026.076.036.111l.015.051.035.111c.006.015.01.036.016.051.015.04.025.076.04.116.005.01.005.02.01.026.01.025.015.045.026.07h18.753c.01-.025.015-.045.025-.07l.06-.178c0-.005.006-.01.006-.015.025-.076.05-.157.076-.233.005-.01.005-.02.01-.03.02-.07.045-.147.066-.223l.015-.045c.025-.086.045-.178.07-.264.016-.055.031-.116.041-.172.005-.02.01-.035.01-.055.005-.03.015-.071.025-.107z"
        fill="#FFD606"
      />
    </svg>
  );
};
