import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TermsAndConditions } from '../../../terms-and-conditions/TermsAndConditions.component';
import { Input } from '../../../shared/components/Input/Input.component';
import { Button } from '../../../shared/components/Button/Button.component';
import styles from './SignUpForm.module.scss';

import { FIRST_NAME, LAST_NAME } from '../../constants/settings_flow.constants';
import { TERMS_AND_CONDITIONS_KEY_STORE } from '../../../terms-and-conditions/constants/terms_and_conditions.constants';

type FamilyMembersSignUpFormProps = {
  success: (formData: { firstName: string; lastname: string }) => void;
};
export const SignUpForm = ({ success }: FamilyMembersSignUpFormProps): JSX.Element => {
  const { t } = useTranslation();

  const [acceptedState, setAcceptedState] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [formErrors, setFormErrors] = useState([] as string[]);
  const [formCanSubmit, setFormCanSubmit] = useState(true);

  const formSubmit = () => {
    if (formCanSubmit) {
      localStorage.setItem(FIRST_NAME, firstName);
      localStorage.setItem(LAST_NAME, lastName);
      localStorage.setItem(TERMS_AND_CONDITIONS_KEY_STORE, '1');
      success({ firstName: firstName, lastname: lastName });
    }
  };

  const handleError = (origin: string, add = true): void => {
    let newErrors = formErrors;

    if (add) {
      const find = newErrors.filter((string) => string === origin);
      if (!find.length) {
        newErrors.push(origin);
      }
    } else {
      newErrors = newErrors.filter((string) => string !== origin);
    }

    setFormErrors(newErrors);
    setFormCanSubmit(!newErrors.length);
  };

  return (
    <>
      <p className={[styles.login_copy, 'font-main-content'].join(' ')}>
        {t('members.login.intro', 'Please fill out the form below to securely join the session.')}
      </p>
      <form className={styles.login_form}>
        <Input
          inputName="first_name"
          label={t('members.login.first_name_label', 'First Name')}
          state={firstName}
          handleError={handleError}
          onInputChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          inputName="last_name"
          label={t('members.login.last_name_label', 'Last Name')}
          state={lastName}
          handleError={handleError}
          onInputChange={(e) => setLastName(e.target.value)}
        />
        <div className={styles.loginForm_termsAndConditionsWrapper}>
          <TermsAndConditions
            acceptedState={acceptedState}
            setAcceptedState={setAcceptedState}
            handleError={handleError}
          />
        </div>
        <div>
          <Button
            backgroundGlobal={formCanSubmit ? 'success' : 'secondary'}
            onClick={formSubmit}
            disabled={!formCanSubmit}
          >
            {t('members.login.submit_button', 'Submit')}
          </Button>
        </div>
      </form>
    </>
  );
};
