import React, { useEffect, useState } from 'react';
import { SHOW_LANGUAGE_ROUTES, LANGUAGE_KEY_STORE, LANGUAGES } from '../../constants/languages.constants';
import { Dropdown, DropdownItem } from '../../../shared/components/Dropdown/Dropdown.component';
import { useTranslation } from 'react-i18next';
import { Language } from '../../types/Language.types';
import { matchPath, useLocation } from 'react-router';
import styles from './LanguageMenu.module.scss';

export const LanguageMenu = (): JSX.Element => {
  const { i18n } = useTranslation();
  const location = useLocation();
  // hide dropdown in some page
  const [showDropdown, setShowDropdown] = useState(false);
  useEffect(
    function showDropdown() {
      setShowDropdown(
        SHOW_LANGUAGE_ROUTES.some((route) => {
          // for match with dynamic route
          return matchPath(location.pathname, {
            path: route,
            exact: true
          });
        })
      );
      setLanguageKey(i18n.language);
    },
    [i18n.language, location]
  );

  // language state in dropdown
  const [languageKey, setLanguageKey] = useState(i18n.language);
  const languageSelected = LANGUAGES.find((l) => l.id === languageKey);
  const onLanguageChange = (data: unknown): void => {
    const language = data as Language;
    i18n.changeLanguage(language.id).then(() => {
      setLanguageKey(language.id);
      localStorage.setItem(LANGUAGE_KEY_STORE, language.id);
    });
  };
  const languages = LANGUAGES.slice().map((l) => {
    const trans: DropdownItem = { ...l };
    trans.text = (
      <div className="d-flex align-items-center h-100">
        {l.img}
        <span className="ml-2">{l.text}</span>
      </div>
    );
    trans.data = l;
    trans.onClick = onLanguageChange;
    return trans;
  });

  return (
    <>
      {showDropdown && (
        <Dropdown
          className={styles.DropdownLang}
          options={languages}
          right={true}
          text={
            languageSelected && (
              <>
                {languageSelected.img}
                <span className="ml-2 txt-uppercase">{languageSelected.id}</span>
              </>
            )
          }
        />
      )}
    </>
  );
};
