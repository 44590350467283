import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { configureStore, history } from './App.store';

export const store = configureStore();

type AppProvidersProps = {
  children: JSX.Element | Array<JSX.Element>;
};

export const AppProviders = ({ children }: AppProvidersProps): JSX.Element => (
  <Provider store={store}>
    <ConnectedRouter history={history}>{children}</ConnectedRouter>
  </Provider>
);
